'use client';

import Image from 'next/image';
import { useResponsiveQuery } from 'atomic-layout';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';

import styles from './StepByStep.module.css';
import { StepCard } from './StepCard';
import { StepHeading } from './StepHeading';
import VideoPlayer from './videoPlayer';
import { StepByStepProps } from './types';
import classNames from 'classnames';

export function StepByStep({
  referId,
  title,
  description,
  steps,
  videoUrl,
}: StepByStepProps) {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const videoRef = useRef<ReactPlayer>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [stepNumber, setStepNumber] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [mockedClick, setMockedClick] = useState(false);
  const step = useMemo(() => steps[stepNumber], [steps, stepNumber]);
  const isTablet = useResponsiveQuery({ from: 'md' });

  useEffect(() => {
    if (videoRef.current && inView) {
      const currentTime = videoRef.current.getCurrentTime();
      const time = step?.videoFrameStart
        ? currentTime - step.videoFrameStart
        : 0;
      const isNextFrame = time > 0 && time < 1;

      if (!isNextFrame && step.videoFrameStart) {
        videoRef.current.seekTo(step.videoFrameStart);
      }
    }
  }, [inView, step]);

  const playAndPauseVideo = useCallback(() => {
    if (videoRef.current) {
      if (!inView) {
        videoRef.current.setState({ play: true });
        videoRef.current.setState({ pause: true });
      }
    }
  }, [videoRef, inView]);

  useEffect(() => {
    const body = document.getElementById('__next');

    if (body) {
      body.addEventListener('touchstart', playAndPauseVideo);
      body.addEventListener('touchend', playAndPauseVideo);
      body.addEventListener('touchmove', playAndPauseVideo);
      body.addEventListener('click', playAndPauseVideo);
    }

    return () => {
      if (body) {
        body.removeEventListener('touchstart', playAndPauseVideo);
        body.addEventListener('touchend', playAndPauseVideo);
        body.addEventListener('touchmove', playAndPauseVideo);
        body.removeEventListener('click', playAndPauseVideo);
      }
    };
  }, [playAndPauseVideo]);

  useEffect(() => {
    if (scrollContainerRef.current && inView) {
      const activeStep = document.getElementsByClassName(
        styles.active,
      )[0] as HTMLDivElement;

      scrollContainerRef.current.scrollTo({
        left: activeStep.offsetLeft - 24,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [step, scrollContainerRef, inView]);

  return (
    <>
      <div
        ref={ref}
        id={referId}
        className="flex overflow-hidden flex-col gap-24 md:gap-24 py-40 lg:py-80 px-24 md:px-24 text-display-900 bg-display-0"
      >
        <div className="block lg:hidden">
          <StepHeading title={title} description={description} />
        </div>
        <div
          ref={scrollContainerRef}
          className="md:grid md:grid-cols-2 gap-24 lg:gap-[40px] md:m-auto max-w-[1280px]"
        >
          {inView && (
            <div className="flex flex-col gap-24 md:gap-36 lg:gap-[40px] md:justify-center w-full">
              <div className="hidden lg:block">
                <StepHeading title={title} description={description} />
              </div>

              <StepCard
                steps={steps}
                step={step}
                src={videoUrl}
                setCurrentTime={setCurrentTime}
                setStepNumber={setStepNumber}
                stepNumber={stepNumber}
                setMockedClick={setMockedClick}
                currentTime={currentTime}
                mockedClick={mockedClick}
                videoRef={videoRef}
                isTablet={isTablet}
              />
            </div>
          )}

          {isTablet && inView && videoUrl ? (
            <VideoPlayer
              videoRef={videoRef}
              step={step}
              stepNumber={stepNumber}
              setCurrentTime={setCurrentTime}
              setStepNumber={setStepNumber}
              steps={steps}
              src={videoUrl}
            />
          ) : (
            isTablet &&
            step?.mockupWeb && (
              <div className="flex justify-center items-center p-40 h-[428px] lg:h-[620px] bg-display-100 rounded-2xl">
                {steps.map((s, sn) => {
                  if (!s?.mockupWeb) return null;

                  return (
                    <Image
                      className={classNames('w-max h-full', {
                        hidden: sn !== stepNumber,
                      })}
                      width={s.mockupWeb.width ? s.mockupWeb.width / 2 : 0}
                      height={s.mockupWeb.height ? s.mockupWeb.height / 2 : 0}
                      src={s.mockupWeb.path}
                      alt={s.mockupWeb.alternativeText}
                      quality={s.mockupWebQuality}
                    />
                  );
                })}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}
