'use client';

import { AdvantagesCard, AdvantagesCardProps } from './AdvantagesCard';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';
import { useContext } from 'react';
import { AmplitudeContext } from '@/contexts/amplitude';
import Button from '../../base/ui/Button';

export type ListCardItemsProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  title: string;
  button: {
    linkHref: string;
    linkHash: string;
    label: string;
  };
  checkCardItem: {
    responsiveImage: AdvantagesCardProps['responsiveImage'];
    className: string;
    tag: string;
    title: string;
    iconDescription: AdvantagesCardProps['iconDescription'];
    cardHeight: string;
  }[];
};

export function ListCardItems({
  referId,
  forwardRef,
  title,
  button,
  checkCardItem,
}: ListCardItemsProps) {
  const { analytics } = useContext(AmplitudeContext);

  return (
    <section
      id={referId}
      ref={forwardRef}
      className="flex flex-col justify-center px-32 text-center !bg-display-100 md:bg-transparent md:rounded-none rounded-t-3xl"
    >
      <div>
        <div className="flex justify-center px-40 mt-40 w-full text-left md:text-center">
          <h2 className="w-[800px] font-display text-4xl font-bold sm:text-center">
            {title}
          </h2>
        </div>

        <div className="flex justify-center px-40 w-full">
          <Button
            sectionReference={referId}
            className="py-12 px-24 mt-20 mb-60 !w-max"
            linkHref={button.linkHref}
            linkHash={button.linkHash}
            onClick={() =>
              analytics?.track({
                event_type: 'cta stone',
                event_properties: {
                  name: 'cta stone',
                  description: `Evento disparado quando o usuário clicar no botão "${button.label}`,
                  section_reference: title,
                  cta_reference: button.label,
                },
              })
            }
            data-testid="button"
          >
            {button.label}
          </Button>
        </div>

        <div className="flex flex-wrap gap-24 justify-center items-end text-left bg-display-100">
          {checkCardItem.map(item => (
            <AdvantagesCard key={item.tag} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
}
