'use client';

import classNames from 'classnames';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { ReactEventHandler, useContext } from 'react';
import { OptionTypeBase } from 'react-select';
import * as yup from 'yup';

import Button from '@/app/components/base/ui/Button';
import FormikSelectInput from '@/containers/InboundStone/components/InboundFormV2/components/FormikSelectInput';
import FormikTextField from '@/containers/InboundStone/components/InboundFormV2/components/FormikTextField';
import { AmplitudeContext } from '@/contexts/amplitude';
import { uuid } from '@/helpers/uuid';
import { isValidCnpj } from '@/helpers/validateDocument';
import { IUtmRexSecondFlow } from '@/hooks/useRex';
import { tpvOptions } from '@/resources/inbound';
import { sendPreleadValues } from '@/services/sendPreleadValues';
import useStore from '@/store';
import { getGASessionId } from '@/utils/amplitude';
import getUtmUrl from '@/utils/getUtmUrl';
import { bffHttp, useHttpClient } from '@/utils/http-client';
import { cnpjMask, phoneMask } from '@/utils/masks';
import translateTpvOptions from '@/utils/translateTpvOptions';

type ExclusiveOffersFormType = {
  textAlign?: string;
  title?: string;
  description?: string;
  button: { label: string; onClick: (e?: ReactEventHandler) => void };
  className?: string;
  tpvField?: boolean;
  isCNPJ?: boolean;
  fullWidthButton?: boolean;
  sectionReference?: string;
};
const ExclusiveOffersForm = (props: ExclusiveOffersFormType) => {
  const {
    textAlign,
    title,
    description,
    button,
    className,
    tpvField,
    isCNPJ,
    fullWidthButton = true,
    sectionReference,
  } = props;
  const { analytics } = useContext(AmplitudeContext);
  const store = useStore();
  const formikInitialValues = {
    name: '',
    email: '',
    phone: '',
    tpv: tpvField ? '' : undefined,
    document: isCNPJ ? '' : undefined,
  };

  const httpClient = useHttpClient(bffHttp);
  async function handleSubmit(
    values: Record<string, string | undefined>,
    { resetForm }: FormikHelpers<Record<string, string | undefined>>,
  ) {
    const { offer_id } = getUtmUrl() as IUtmRexSecondFlow;
    const formattedPhone = String(values.phone?.replace(/\D/g, ''));
    const formattedDocument = String(values.document?.replace(/\D/g, ''));
    const planId = isCNPJ
      ? '33333333-3333-3333-3333-333333333333'
      : '00000000-0000-0000-0000-000000000000';

    const dataForm = {
      ...(isCNPJ ? { document: formattedDocument } : {}),
      full_name: String(values.name),
      phone: formattedPhone,
      email: String(values.email),
    };
    await store.updateWithDadosData({
      user: {
        document: '',
        email: String(values.email),
        full_name: String(values.name),
        phone: formattedPhone,
      },
      organization: store.organization,
    });
    resetForm();

    await sendPreleadValues(httpClient.fetch, {
      ...dataForm,
      plan_id: values.plan_id ?? planId,
      session_id: uuid(),
      tpv:
        values.tpv === undefined ? undefined : translateTpvOptions(values.tpv),
      email: String(values.email),
      offer_id: offer_id ?? planId,
      tracking: {
        coupon: store.coupon,
        utm_medium: store.utm_medium as string,
        utm_source: store.utm_source as string,
        utm_campaign: store.utm_campaign as string,
        utm_content: store.utm_content as string,
        utm_term: store.utm_term as string,
        utm_placement: store.utm_placement as string,
        fbid: store.fbid as string,
        gcid: getGASessionId(),
        client_id: store.client_id as string,
        user_id: store.user_id as string,
      },
    });
  }
  const trackOnBlurFields = (inputType: string) => {
    analytics?.track({
      event_type: `step ${inputType} completed`,
      event_properties: {
        name: `step ${inputType} completed`,
        description: `Evento disparado quando o usuário preenche o ${inputType}. Como propriedade será retornada qual o ${inputType} digitado`,
      },
    });
  };
  const schema = yup.object({
    name: yup.string().trim().required('Este campo é obrigatório!'),
    email: yup
      .string()
      .email('Informe um e-mail válido')
      .required('Este campo é obrigatório!'),
    phone: yup
      .string()
      .matches(/\(\d{2,}\) \d{4,}-\d{4}/g, 'Informe um telefone válido')
      .required('Este campo é obrigatório!'),
    tpv: tpvField
      ? yup.string().required('Este campo é obrigatório!')
      : yup.string(),
    document: isCNPJ
      ? yup
          .string()
          .required('Este campo é obrigatório')
          .length(18, 'Verifique o seu CNPJ')
          .matches(
            /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
            'Informe um CNPJ válido',
          )
          .test({
            name: 'isCnpjValid',
            skipAbsent: true,
            test(value, ctx) {
              if (!isValidCnpj(value)) {
                return ctx.createError({ message: 'Informe um CNPJ válido' });
              }
              return true;
            },
          })
      : yup.string(),
  });

  return (
    <Formik<Record<string, string | undefined>>
      enableReinitialize
      initialValues={formikInitialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {props => {
        const isButtonDisabled = !props.isValid;
        return (
          <Form>
            <div className={`flex flex-col ${textAlign} max-w-[440px]`}>
              <p className="mb-8 font-display font-bold heading-5">{title}</p>
              <p>{description}</p>
            </div>
            <div className="flex flex-col gap-16 mt-32 mb-16">
              <FormikTextField
                label="Nome"
                name="name"
                onBlurCallBack={(
                  event: React.FocusEvent<HTMLInputElement, Element>,
                ) => {
                  if (event.target.value) {
                    trackOnBlurFields(event.target.name);
                  }
                }}
              />
              {isCNPJ && (
                <FormikTextField
                  type="text"
                  name="document"
                  label="CNPJ"
                  aria-label="CNPJ"
                  maskFunction={cnpjMask}
                  onBlurCallBack={(
                    event: React.FocusEvent<HTMLInputElement, Element>,
                  ) => {
                    if (event.target.value) {
                      trackOnBlurFields(event.target.name);
                    }
                  }}
                />
              )}
              <FormikTextField
                type="email"
                name="email"
                label="E-mail"
                onBlurCallBack={(
                  event: React.FocusEvent<HTMLInputElement, Element>,
                ) => {
                  if (event.target.value) {
                    trackOnBlurFields(event.target.name);
                  }
                }}
              />
              <FormikTextField
                type="tel"
                name="phone"
                label="Telefone"
                maskFunction={phoneMask}
                onBlurCallBack={(
                  event: React.FocusEvent<HTMLInputElement, Element>,
                ) => {
                  if (event.target.value) {
                    trackOnBlurFields(event.target.name);
                  }
                }}
              />
            </div>
            {tpvField && (
              <Field id="tpv" name="tpv" aria-label="Faturamento mensal">
                {(fieldProps: FieldProps) => (
                  <FormikSelectInput
                    label="Faturamento mensal em cartão"
                    description="Estimativa de vendas feitas em cartão de crédito e débito."
                    options={tpvOptions}
                    iid="tpvSelect"
                    id="react-select-tpvSelect-input"
                    aria-label="Faturamento mensal"
                    name={fieldProps.field.name}
                    {...fieldProps}
                    onBlurCallBack={(option: OptionTypeBase) => {
                      const name = 'revenue';
                      const value = option.value;
                      if (!window) return;
                      analytics?.track({
                        event_type: `step ${name} completed ton`,
                        event_properties: {
                          [`input_${name}`]: value,
                          name: `step ${name} completed ton`,
                          description:
                            'Evento disparado quando o usuário seleciona o faturamento. Como propriedade será retornada qual faturamento selecionado',
                        },
                      });
                    }}
                  />
                )}
              </Field>
            )}
            <div className="mt-24">
              <p className="text-sm text-center">
                Ao continuar, você concorda com nosso &nbsp;
                <a
                  href="https://docs.stone.com.br/aviso-de-privacidade/"
                  target="_blank"
                >
                  <span className="text-sm text-stone-500 cursor-pointer">
                    Aviso de Privacidade
                  </span>
                </a>
                .
              </p>
            </div>
            <div className={className}>
              <Button
                data-testid="button-formik"
                type="submit"
                className={classNames(
                  fullWidthButton ? '!w-full' : '',
                  'mt-32',
                )}
                onClick={() => {
                  button.onClick();
                  analytics?.track({
                    event_type: 'Forms Button stone',
                    event_properties: {
                      name: 'Forms Button stone',
                      description: `Quando o usuário preencher os dados do formulário da ultima sessão e clicar em ${button.label}`,
                      section_reference: sectionReference,
                      cta_reference: button.label,
                    },
                  });
                }}
                disabled={isButtonDisabled}
                sectionReference={title || description || 'ExclusiveOffersForm'}
              >
                {button.label}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ExclusiveOffersForm;
