'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import Icon, { IconsNames } from '@/components/v3/Icon';
import { ColorProps, ImageCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';

const styles = tv({
  slots: {
    container:
      'flex flex-col md:flex-row justify-center items-center px-24 xl:px-0',
    containerTitle: '',
  },
  variants: {
    variant: {
      flex: {
        container: 'gap-40 py-[80px] lg:py-[160px]',
        containerTitle:
          'flex flex-col md:justify-center max-w-[480px] lg:max-w-[700px]',
      },
      grid: {
        container: 'w-full gap-16 md:gap-24 lg:gap-40 lg:p-56 py-40 lg:py-80',
        containerTitle: 'gap-16 w-full max-w-[455px] md:max-w-[510px]',
      },
      'mobile-image-inverted': {
        container: 'gap-40 py-[80px] lg:py-[160px] flex-col-reverse',
        containerTitle: 'gap-16 w-full max-w-[455px] md:max-w-[510px]',
      },
    },
  },
});

export interface TBannerCardProps {
  referId: string;
  tag?: string;
  title: string;
  description?: string;
  reverse: boolean;
  iconDescriptions: {
    iconName: IconsNames;
    description: string;
  }[];
  image?: ImageCMSProps;
  imageQuality?: number;
  buttons: ButtonProps[];
  variant?: 'grid' | 'flex' | 'mobile-image-inverted';
  backgroundColor?: ColorProps;
  videoUrl?: string;
}

export function SimpleCard({
  referId,
  tag,
  title,
  description,
  reverse,
  iconDescriptions,
  image,
  imageQuality,
  buttons,
  variant,
  backgroundColor,
  videoUrl,
}: TBannerCardProps) {
  const classes = styles({
    variant: variant ?? 'grid',
  });

  return (
    <div
      id={referId}
      className={classNames(
        twMerge(`${backgroundColor}`, classes.container()),
        { 'md:flex-row-reverse': reverse },
      )}
    >
      {tag && (
        <p
          className="md:hidden w-full max-w-[455px] md:max-w-[510px] font-bold text-stone-700 uppercase paragraph-14"
          dangerouslySetInnerHTML={{
            __html: tag,
          }}
        />
      )}
      <div className="flex overflow-hidden justify-center items-center my-16 md:my-0 w-full md:w-auto lg:min-w-[455px] max-w-[455px] rounded-md md:rounded-2xl">
        {image && (
          <Image
            src={image.path}
            alt={image.alternativeText}
            width={455}
            height={455}
            quality={imageQuality}
            className="object-cover w-full h-full"
          />
        )}
        {videoUrl && !image && (
          <video width="100%" height="100%" muted playsInline loop autoPlay>
            <source src={videoUrl} type="video/webm" />
            <source src={videoUrl} type="video/mp4" />
          </video>
        )}
      </div>
      <div className=" gap-16 w-full max-w-[455px] md:max-w-[510px]">
        {tag && (
          <p
            className="hidden md:block font-bold text-stone-700 uppercase paragraph-14"
            dangerouslySetInnerHTML={{
              __html: tag,
            }}
          />
        )}
        <h2
          className="font-display text-3xl lg:text-4xl font-bold leading-7 lg:leading-8"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {description && (
          <p
            className="mt-8 md:w-[429px] lg:w-[510px] paragraph-16 md:paragraph-18"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}

        {iconDescriptions && (
          <div className="flex flex-col">
            {iconDescriptions?.map(({ iconName, description }, index) => (
              <div className="flex flex-col gap-16 mt-16" key={index}>
                <div className="flex">
                  <Icon
                    name={iconName}
                    className="mt-4 mr-16 w-24 min-w-[24px] h-24 min-h-[24px] text-stone-500 fill-current"
                  />
                  <p
                    className="paragraph-18"
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {buttons.length > 0 && (
          <div className="flex flex-col md:flex-row gap-16 mt-16 md:mt-24 w-full">
            {buttons?.map((props, index) => (
              <Button
                key={`${props.label}-${index}`}
                {...props}
                sectionReference={title}
                className="w-full md:w-max"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
