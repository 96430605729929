'use client';

import { useField } from 'formik';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

import Icon, { IconsNames } from '@/components/v3/Icon';

import styles from './FormikTextField.module.css';

interface IFormikTextField extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  description?: string;
  name: string;
  rightIcon?: IconsNames;
  maskFunction?: (value: string) => string;
  inputClassName?: CSSProperties;
  onBlurCallBack?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}

const FormikTextField: React.FC<IFormikTextField> = ({
  label,
  description,
  maskFunction,
  rightIcon,
  onBlurCallBack,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasAutoFill, setHasAutoFill] = useState(false);
  const [field, meta, helpers] = useField(props.name);

  const activeLabel = useMemo(
    () => hasAutoFill || isFocused || field.value,
    [isFocused, field.value, hasAutoFill],
  );

  useEffect(() => {
    const input = document.getElementById(props.name) as HTMLInputElement;
    if (input) {
      setHasAutoFill(input.value !== '');
    }
  }, [props.name, field.value]);

  return (
    <div>
      <div
        className={`${styles.inputContainer}
        ${rightIcon ? styles.inputContainerHasRightIcon : ''} 
        ${meta.error && meta.touched ? styles.hasError : ''} ${
          isFocused ? styles.inputContainerFocused : ''
        }`}
      >
        <label
          htmlFor={props.name}
          className={`${styles.label} ${activeLabel ? styles.labelActive : ''}`}
        >
          {label}
        </label>
        <input
          {...field}
          {...props}
          id={props.name}
          type={props.type || 'text'}
          className={styles.input}
          onChange={event => {
            if (maskFunction) {
              helpers.setValue(maskFunction(event.currentTarget.value));
            } else {
              field.onChange(event);
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={e => {
            if (onBlurCallBack) onBlurCallBack(e);
            setIsFocused(false);
            field.onBlur(e);
          }}
        />
        {rightIcon && <Icon className={styles.rightIcon} name={rightIcon} />}
      </div>
      {description && (
        <p className="mt-4 text-display-700 paragraph-14">{description}</p>
      )}
      {meta.error && meta.touched && (
        <p
          id={`${props.name}-error`}
          className="mt-4 text-red-500 paragraph-14"
        >
          {meta.error}
        </p>
      )}
    </div>
  );
};

export default FormikTextField;
