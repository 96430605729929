'use client';

import React, { useEffect, useState } from 'react';
import { TabDescription } from './TabDescription';

export interface Items {
  title: string;
  items?: {
    title: string;
    item: {
      label: string;
      value: string;
    }[];
  };
}

interface Props {
  title: string;
  items: Items[];
}
export function Fees({ title, items }: Props) {
  const [openedDescription, setOpenedDescription] = useState<null | number>(0);
  const [waitScrollToTop, setWaitScrollToTop] = useState<boolean>(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setWaitScrollToTop(true);
    }, 1000);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <div className="mx-auto max-w-[455px] md:max-w-none">
      <h2
        className="mb-24 font-display text-stone-500 heading-3"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <ul className="flex flex-col">
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {items?.map(({ item, title }: any, index) => (
          <TabDescription
            isOpen={waitScrollToTop && openedDescription === index}
            setIsOpen={() =>
              setOpenedDescription(value => (value !== index ? index : null))
            }
            key={title}
            title={title}
            items={item}
          />
        ))}
      </ul>
    </div>
  );
}
