'use client';

import { useMediaQuery } from 'atomic-layout';
import Image from 'next/image';
import { useContext } from 'react';

import { AmplitudeContext } from '@/contexts/amplitude';
import { ResponsiveImagesWithAttributes } from '@/types/cms';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';
import Button, { ButtonProps } from '../../base/ui/Button';

export type TitleDescriptionButtonCardProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  title: string;
  description: string;
  button: ButtonProps;
  titleImageDescription: {
    responsiveImage: ResponsiveImagesWithAttributes;
    description: string;
  }[];
};

export function TitleDescriptionButtonCard({
  referId,
  forwardRef,
  title,
  description,
  button,
  titleImageDescription,
}: TitleDescriptionButtonCardProps) {
  const { analytics } = useContext(AmplitudeContext);
  const smallScreen = useMediaQuery({ minWidth: 350, maxWidth: 440 });
  const isScreenSmall = smallScreen ? 'w-[330px]' : 'w-[390px]';

  return (
    <section id={referId} ref={forwardRef} className="w-full">
      <div className="flex flex-col w-full text-center">
        <div className="flex flex-col m-auto w-full max-w-[1276px] bg-stone-900 rounded-[24px]">
          <div className="flex flex-col gap-16 lg:gap-24 p-[40px] sm:px-[40px] lg:px-[80px] sm:pt-[80px] lg:pt-[120px] sm:pb-[40px] lg:pb-[40px] m-auto text-center ">
            <div className="flex flex-col gap-16 sm:gap-[8px] justify-center w-full">
              <div className="flex flex-col sm:flex-row justify-center w-full">
                <h2
                  className="font-display text-4xl md:text-6xl font-bold text-display-0"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </div>
              <div className="flex flex-row sm:justify-center w-full">
                <p className="text-base md:text-xl text-display-0">
                  {description}
                </p>
              </div>
            </div>
            <div className="flex justify-center">
              <Button
                {...button}
                sectionReference={title}
                onClick={() => {
                  analytics?.track({
                    event_type: 'cta stone',
                    event_properties: {
                      name: 'cta stone',
                      description: `Evento disparado quando o usuário clicar no botão "${button.label}"`,
                      section_reference: title,
                      cta_reference: button.label,
                    },
                  });
                }}
              >
                {button.label}
              </Button>
            </div>
          </div>

          <div
            className={`flex flex-col md:flex-row justify-center md:mx-[40px] self-center md:w-[624px] lg:w-[780px] bg-stone-100 rounded-lg ${isScreenSmall} mb-[80px]`}
          >
            <div
              className={`flex flex-col gap-16 p-[40px] h-[311px] bg-stone-400 rounded-lg ${isScreenSmall}`}
            >
              <h6 className="font-display font-bold">Suas vendas pela Stone</h6>
              <div>
                <Image
                  src="site-stone/NewWhyStone/por-que-stone-grafico-vendas-pela-stone"
                  width={248}
                  height={147}
                  alt="gráfico com fundo verde enfatizando o número R$53 mil"
                  quality={80}
                />
              </div>
              <p>{titleImageDescription[0].description}</p>
            </div>
            <div
              className={`flex flex-col gap-16 p-[40px] h-[311px] rounded-lg ${isScreenSmall}`}
            >
              <h6 className="font-display font-bold">Suas taxas pela Stone</h6>
              <div>
                <Image
                  src="site-stone/NewWhyStone/por-que-stone-grafico-taxas-pela-stone"
                  width={260}
                  height={147}
                  alt="gráfico com fundo verde enfatizando o número 1,08%"
                  quality={80}
                />
              </div>
              <p>{titleImageDescription[1].description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
