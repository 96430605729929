/**
 * TODO: Sei que esse formatador é válido nos browsers, mas não tenho certeza se
 * pode ser usado da mesma forma fora dele.
 * */
export function coinFormatter(value: number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

const regex = /[,\.]/g;

export const formattedMoney = (value: string) => {
  return Number(
    value
      .replace('R$', '')
      .replaceAll(regex, '')
      .replaceAll(',', '')
      .replaceAll(' ', ''),
  );
};
