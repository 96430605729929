'use client';

import { useResponsiveQuery } from 'atomic-layout';
import classNames from 'classnames';
import Image from 'next/image';

import { ImageCMSProps } from '@/types/cms';

export interface MarkProps {
  logo: ImageCMSProps;
  quality: number;
  onDesktopOnly: boolean;
}

export function Mark({ logo, quality, onDesktopOnly }: MarkProps) {
  const isMobile = useResponsiveQuery({ from: 'xxs', to: 'xl' });
  const designedProportionalSizeReductionForMobile = 0.7727;

  let width = logo.width ? logo.width / 2 : undefined;
  let height = logo.height ? logo.height / 2 : undefined;

  if (isMobile && width && height) {
    width = width * designedProportionalSizeReductionForMobile;
    height = height * designedProportionalSizeReductionForMobile;
  }

  return (
    <Image
      key={`${logo.id}-${logo.name}`}
      src={logo.path}
      alt={logo.alternativeText}
      width={width}
      height={height}
      quality={quality || 70}
      className={classNames({
        'hidden lg:flex': onDesktopOnly,
      })}
    />
  );
}
