import styles from './StepByStep.module.css';
import { StepTablet } from './types';

export const StepTable: React.FC<StepTablet> = ({
  stepNumber,
  index,
  title,
  description,
  mockedClick,
  currentTime,
  step,
  tag,
}: StepTablet) => {
  function getPercentToFillThisStep() {
    if (!step.videoFrameStart || !step.videoFrameEnd) return 100;

    return (
      ((currentTime - step.videoFrameStart) /
        (step.videoFrameEnd - step.videoFrameStart)) *
      100
    );
  }

  function getProgress() {
    const thereAreNoFrames = !step.videoFrameStart || !step.videoFrameEnd;
    const shouldFillThisStepFull = mockedClick || thereAreNoFrames;

    return stepNumber === index
      ? shouldFillThisStepFull
        ? 100
        : getPercentToFillThisStep()
      : 0;
  }

  return (
    <>
      <div className="hidden md:block absolute h-full ">
        <progress
          className={styles['progress-bar']}
          id={`progress-${index}`}
          value={getProgress()}
          max="100"
        />
      </div>
      <div className=" pl-16 lg:pl-24">
        <h5
          className={`md:w-full paragraph-18 lg:paragraph-20 font-medium font-display leading-[26px] lg:leading-[28px] flex flex-col-reverse xl:flex-row xl:items-center xl:gap-4  ${
            stepNumber === index ? 'text-stone-500' : 'text-display-900'
          }`}
        >
          {title}{' '}
          {tag && (
            <span className="block xl:inline xl:absolute xl:right-[-15px] py-[4px] px-[8px] w-[fit-content] text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px] text-black bg-stone-200 rounded-[16px]">
              {tag}
            </span>
          )}
        </h5>
        <p className="mt-4 font-sharon leading-[24px] lg:leading-[26px] paragraph-16 lg:paragraph-18">
          {description}
        </p>
      </div>
    </>
  );
};
