'use client';

import { useCMSLink } from '@/hooks/useCMSLink';
import { CardProps } from './Card';
import Link from 'next/link';

export function LinkWrapper({
  linkHref,
  title,
  children,
}: CardProps & { children: React.ReactNode }) {
  const { getHrefFromCMSParams } = useCMSLink();

  if (!linkHref) return children;

  return (
    <Link
      href={getHrefFromCMSParams({
        href: linkHref,
      })}
      aria-label={`Link para a sessão ${title}`}
    >
      {children}
    </Link>
  );
}
