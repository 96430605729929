'use client';

import { IconsNames } from '@/components/v3/Icon';
import Modal from '@/components/v3/Modal';
import {
  TrackSelectedPlansEvent,
  TrackSelectedPlansInDetailsEvent,
} from '@/containers/MGM/components/Plan/MultiCards';
import PlanDetails from '@/containers/Plans/components/Plans/components/PlanDetails';
import { IPlan } from '@/containers/Plans/components/Plans/planType';

import { AmplitudeContext } from '@/contexts/amplitude';
import { usePlans } from '@/contexts/plans';
import { useMgm } from '@/hooks/useMgm';
import { definePlansOrderOnMgm } from '@/services/definePlansOrderOnMgm';
import { getCouponParam } from '@/services/getCouponParam';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useStore from '@/store';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';
import PlansCardPj from './components/PlansCardPj';
import PlansCardFlex from './components/PlansCardFlex';
import PlansCards from './components/PlansCards';

type PlansCardSectionProps = {
  referId: string;
  forwardRef: UseInViewRefType;
  plans: IPlan[];
  title: string;
  iconsDescriptions: {
    iconName: IconsNames;
    description: string;
  }[];
};

const whatsappMessage =
  'Protocolo%20de%20Atendimento:%20CONS3011%20Oi!%20Eu%20gostaria%20de%20adquirir%20uma%20maquininha.%20Voc%C3%AA%20pode%20me%20ajudar?';

export function PlansCard({
  referId,
  forwardRef,
  title,
  plans,
}: PlansCardSectionProps) {
  const [showModal, setShowModal] = useState(false);
  const [planDetailsSelected, setPlanDetailsSelected] = useState(0);
  const [isMgm] = useMgm();
  const { handleAutoCredSubmit, setCouponParam, couponParam } = usePlans();
  const { analytics } = useContext(AmplitudeContext);
  const plansSorted = useMemo(
    () => (isMgm ? definePlansOrderOnMgm(plans) : plans),
    [isMgm, plans],
  );
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const isTonPlanName = (planName: string) => {
    return planName === 'Modo Conta PJ';
  };

  useEffect(() => {
    setCouponParam(getCouponParam());
  }, [setCouponParam]);

  const trackSelectedPlansEvent = ({
    cta_reference,
    plan,
  }: TrackSelectedPlansEvent) => {
    analytics?.track({
      event_type: 'plans chosen Stone',
      event_properties: {
        name: 'plans chosen Stone',
        description: `Quando usuário clica no botão dentro do card do ${plan}`,
        section_reference: title,
        plan,
        cta_reference,
      },
    });
  };

  const trackSelectedPlansInDetailsEvent = ({
    cta_reference,
    plan,
  }: TrackSelectedPlansInDetailsEvent) => {
    analytics?.track({
      event_type: 'plans detail Stone',
      event_properties: {
        name: 'plans detail Stone',
        description: `Evento disparado quando o usuário clicar no botão "mais detalhes"`,
        section_reference: title,
        cta_reference,
        plan,
      },
    });
  };

  const trackSelectedPlansInDetailsButtonEvent = ({
    cta_reference,
    plan,
  }: TrackSelectedPlansInDetailsEvent) => {
    analytics?.track({
      event_type: 'Plan - Details Contracted Stone',
      event_properties: {
        name: 'Plan - Details Contracted Stone',
        description: `Quando o usuario clica em Escolher plano dentro do modal de detalhes do plano`,
        section_reference: 'Modal - Mais detalhes dos planos',
        cta_reference,
        plan,
      },
    });
  };

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina',
          section_reference: title,
        },
      });
    }
  }, [analytics, inView, title]);

  const store = useStore();

  return (
    <section id={referId} ref={forwardRef} className="pb-20 bg-stone-900">
      <Modal
        open={showModal}
        closeOnClickOut
        onCloseClick={() => setShowModal(false)}
      >
        <div
          className={`flex md:overflow-y-auto absolute inset-0 md:inset-x-0 md:top-0 z-50 md:justify-center md:p-24 md:m-auto w-full md:max-h-[910px] md:h-full md:bg-transparent`}
        >
          <PlanDetails
            {...(plansSorted[planDetailsSelected] as IPlan)}
            title={plansSorted[planDetailsSelected]?.plan_name}
            handleCloseModal={() => setShowModal(false)}
            isOpen={showModal}
            submit={() => {
              trackSelectedPlansInDetailsButtonEvent({
                cta_reference: `Contrate agora ${plansSorted[planDetailsSelected].plan_name}`,
                plan: isTonPlanName(plansSorted[planDetailsSelected].plan_name)
                  ? 'Modo Conta PJ'
                  : plansSorted[planDetailsSelected].plan_name,
              });
              handleAutoCredSubmit(plansSorted[planDetailsSelected].plan_name);
            }}
          />
        </div>
      </Modal>
      <div ref={ref}>
        <h3 className="mx-24 mb-40 font-display font-bold text-center text-white">
          Ative o Modo Stone que mais combina com seu negócio
        </h3>
        <div className="flex justify-center">
          <div className="flex flex-wrap gap-24 lg:gap-40 justify-center place-items-center mx-16">
            {plansSorted.map((plan, index) => {
              const {
                plan_name: planName,
                offer_description: description,
                offer_id,
              } = plan;

              return isTonPlanName(planName) ? (
                <PlansCardPj
                  key={plan.offer_id}
                  onSelectPlan={() => {
                    trackSelectedPlansEvent({
                      cta_reference: 'Abra sua conta grátis',
                      plan: 'Modo Conta PJ',
                    });
                  }}
                  onSeeMoreDetails={() => {
                    setShowModal(true);
                    setPlanDetailsSelected(index);
                  }}
                />
              ) : ['Flex', 'Modo Flex'].includes(planName) ? (
                <PlansCardFlex
                  key={plan.offer_id}
                  offerId={offer_id}
                  planName={planName}
                  coupon={couponParam}
                  description={description}
                  onSeeMoreDetails={() => {
                    setPlanDetailsSelected(index);
                    trackSelectedPlansInDetailsEvent({
                      cta_reference: 'Mais detalhes',
                      plan: 'Modo Flex',
                    });
                    setShowModal(true);
                  }}
                  onSelectPlan={() => {
                    trackSelectedPlansEvent({
                      cta_reference: 'Fale com a gente',
                      plan: planName,
                    });
                    window.open(
                      `https://api.whatsapp.com/send?phone=552140421163&text=${whatsappMessage}`,
                      '_blank',
                    );
                  }}
                />
              ) : (
                <PlansCards
                  key={plan.offer_id}
                  offerId={offer_id}
                  planName={planName}
                  coupon={couponParam}
                  description={description}
                  onSeeMoreDetails={() => {
                    setPlanDetailsSelected(index);
                    trackSelectedPlansInDetailsEvent({
                      cta_reference: 'Mais detalhes',
                      plan: planName,
                    });
                    setShowModal(true);
                  }}
                  onSelectPlan={() => {
                    trackSelectedPlansEvent({
                      cta_reference: 'Escolha suas maquininhas',
                      plan: planName,
                    });
                    !store.user.full_name && handleAutoCredSubmit(planName);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
