'use client';

import { ImageCMSProps } from '@/types/cms';
import Button, { ButtonProps } from '../../base/ui/Button';
import { Video } from './Video';

export interface Simple9x16VideoProps {
  referId: string;
  title: string;
  thumbnail: ImageCMSProps;
  thumbnailQuality: number;
  videoUrl: string;
  isLCP: boolean;
  description: string;
  buttons: ButtonProps[];
}

export function Simple9x16Video(props: Simple9x16VideoProps) {
  const { referId, buttons, title, description } = props;

  return (
    <section
      id={referId}
      className="flex flex-col gap-24 items-center py-40 px-24 lg:px-[165px] mx-auto max-w-screen-xl"
    >
      <h1
        className="text-center heading-1"
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Video {...props} />

      <p
        className="font-display text-4xl leading-8 text-center"
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <div className="flex flex-col gap-16">
        <div>
          {buttons?.map(button => (
            <Button key={button.label} {...button} />
          ))}
        </div>
        <div className="flex flex-col items-center">
          <p className="text-sm text-center">Ou ligue</p>
          <a
            href="tel:2140404991"
            className="font-sharon text-xl font-bold leading-5 text-center text-stone-500"
          >
            (21) 4040 4991
          </a>
        </div>
      </div>
    </section>
  );
}
