import { IPlan } from '@/containers/Plans/components/Plans/planType';

export const definePlansOrderOnMgm = (plans: IPlan[]) => {
  const specificOrder = [
    'Modo Parcelado',
    'Modo à Vista',
    'Modo Flex',
    'Modo Conta PJ',
  ];

  const plansSorted = plans.sort((a, b) => {
    return (
      specificOrder.indexOf(a.plan_name) - specificOrder.indexOf(b.plan_name)
    );
  });

  return plansSorted;
};
