'use client';

import Link from 'next/link';
import React, { useContext, useEffect, useState } from 'react';

import Icon from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';

interface FloatingButtonProps {
  isVisibleCTA?: boolean;
  message?: string;
  isModeStone?: boolean;
}

export function FloatingButton({
  isVisibleCTA = true,
  message,
  isModeStone,
}: FloatingButtonProps) {
  const [isVisible, setIsVisible] = useState(isVisibleCTA);
  const { analytics } = useContext(AmplitudeContext);
  const numberPhone = '552140421163';
  const whatsappMessage =
    message ||
    'Olá,%20gostaria%20de%20saber%20mais%20sobre%20como%20posso%20adquirir%20as%20maquininhas%20Stone.%20Voc%C3%AA%20consegue%20me%20ajudar?';

  useEffect(() => {
    if (isVisibleCTA) {
      setIsVisible(isVisibleCTA);
    }
  }, [isVisibleCTA]);

  return (
    <div
      className={`${
        isVisible ? 'flex' : 'hidden md:flex'
      } fixed md:right-40 bottom-0 md:bottom-40 z-[50] px-24 py-16 md:p-0 w-full md:!w-auto bg-display-0 md:bg-transparent`}
    >
      <Link
        href={`https://api.whatsapp.com/send?phone=${numberPhone}&text=${whatsappMessage}`}
        id="button-whatsapp-float"
        target="_blank"
        className="w-full md:!w-auto md:border-[3px] border-display-0 md:border-solid btn btn-large btn-primary md:btn-primary-neon"
        onClick={() => {
          analytics?.track({
            event_type: 'cta stone',
            event_properties: {
              name: 'cta stone',
              description:
                'Evento disparado quando o usuário clicar no botão de "Fale com a gente" fixo em tela e for redirecionado para o whatsapp no mobile',
              section_reference: 'cta flutuante',
              cta_reference: 'Fale com a gente',
            },
          });

          isModeStone &&
            window.dataLayer &&
            window.dataLayer.push({
              event: 'genericClickModoStone',
            });
        }}
      >
        <Icon
          name="whatsapp"
          className="text-display-0 md:text-display-900 fill-current"
        />
        <span className="ml-8 md:text-display-900">Fale com a gente</span>
      </Link>
    </div>
  );
}
