type TrackStatus = {
  video_played: boolean;
  video_view_partial_25: boolean;
  video_view_partial_50: boolean;
  video_view_partial_100: boolean;
};
export const handleTimeUpdate = (
  video: HTMLVideoElement,
  trackStatus: TrackStatus,
  setTrackStatus: React.Dispatch<React.SetStateAction<TrackStatus>>,
  trackPartialReproduction?: (percentage: number) => void,
  trackPlayVideo?: () => void,
) => {
  const currentTime = video.currentTime;
  const duration = video.duration;
  const percentage = (currentTime / duration) * 100;
  if (!trackStatus.video_played && percentage >= 1 && trackPlayVideo) {
    trackPlayVideo();
    setTrackStatus({
      ...trackStatus,
      video_played: true,
    });
  } else if (
    !trackStatus.video_view_partial_25 &&
    percentage >= 25 &&
    trackPartialReproduction
  ) {
    trackPartialReproduction(25);
    setTrackStatus({
      ...trackStatus,
      video_view_partial_25: true,
    });
  } else if (
    !trackStatus.video_view_partial_50 &&
    percentage >= 50 &&
    trackPartialReproduction
  ) {
    trackPartialReproduction(50);
    setTrackStatus({
      ...trackStatus,
      video_view_partial_50: true,
    });
  } else if (
    !trackStatus.video_view_partial_100 &&
    percentage >= 100 &&
    trackPartialReproduction
  ) {
    trackPartialReproduction(100);
    setTrackStatus({
      ...trackStatus,
      video_view_partial_100: true,
    });
  }
};
