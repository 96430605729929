import Icon from '@/components/v3/Icon';

import Image from 'next/image';
import { StepMobileProps } from './types';
import VideoPlayer from './videoPlayer';
import classNames from 'classnames';

export const StepMobile: React.FC<StepMobileProps> = ({
  stepNumber,
  index,
  title,
  description,
  videoUrl,
  step,
  steps,
  setCurrentTime,
  setStepNumber,
  videoRef,
  src,
  tag,
}: StepMobileProps) => {
  return (
    <div className="flex md:hidden flex-col gap-16 justify-center w-full">
      <div>
        {tag && (
          <span className="block xl:inline py-[2px] px-[8px] w-[fit-content] text-[16px] font-medium leading-[20px] text-black bg-stone-200 rounded-[16px]">
            {tag}
          </span>
        )}
        <div className="flex gap-24">
          <h5
            className={`w-full font-medium font-display paragraph-18 lg:paragraph-20 leading-[26px] lg:leading-[28px] flex items-center justify-between  ${
              stepNumber === index ? 'text-stone-500' : 'text-display-900'
            }`}
          >
            {title}
          </h5>
          <div>
            {stepNumber === index ? (
              <Icon name="minus" />
            ) : (
              <Icon name="plus" />
            )}
          </div>
        </div>
        <p
          className={`mt-4 font-sharon leading-[24px] paragraph-16 ${
            stepNumber === index ? 'block' : 'hidden'
          }`}
        >
          {description}
        </p>
      </div>

      {stepNumber === index && videoUrl && (
        <VideoPlayer
          step={step}
          stepNumber={stepNumber}
          setCurrentTime={setCurrentTime}
          setStepNumber={setStepNumber}
          steps={steps}
          videoRef={videoRef}
          src={src}
          className="pt-[21px] pr-[30px] pl-[29px] m-auto w-[312px] h-[312px]"
        />
      )}

      {!videoUrl && step?.mockupMobile && (
        <div
          className={classNames(
            'bg-display-100 flex justify-center items-end pt-24 pl-24 pr-24 rounded-2xl min-h-[312px]',
            {
              hidden: stepNumber !== index,
            },
          )}
        >
          <Image
            className={classNames('w-max h-full', {
              hidden: stepNumber !== index,
            })}
            width={step.mockupMobile.width ? step.mockupMobile.width / 2 : 0}
            height={step.mockupMobile.height ? step.mockupMobile.height / 2 : 0}
            src={step.mockupMobile.path}
            alt={step.mockupMobile.alternativeText}
            quality={step.mockupMobileQuality}
          />
        </div>
      )}
    </div>
  );
};
