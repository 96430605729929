'use client';

import React, { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { YouTubeProps } from 'react-youtube';

import PlayButton from '@/assets/icons/general/play-button.svg';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const YouTube = dynamic<any | YouTubeProps>(() => import('react-youtube'), {
  ssr: false,
});

import styles from './Video.module.css';

interface IVideo {
  id: string;
  img: string;
  imgSrcset: string;
  startPlaying?: boolean;
}

const Video: React.FC<IVideo> = ({ id, img, imgSrcset, startPlaying }) => {
  const [showVideo, toggleShowVideo] = useState(false);

  const handleToggleShowVideo = useCallback(
    () => toggleShowVideo(value => !value),
    [],
  );

  useEffect(() => {
    if (startPlaying) {
      toggleShowVideo(true);
    }
  }, [startPlaying]);

  return (
    <div className={styles.container}>
      {showVideo ? (
        <div className={styles.video_container}>
          <YouTube
            videoId={id}
            opts={{ playerVars: { autoplay: 1, rel: 0 } }}
            onReady={(e: { target: { playVideo: () => void } }) =>
              e.target.playVideo()
            }
          />
        </div>
      ) : (
        <>
          <div
            className={styles.play_button_container}
            onClick={handleToggleShowVideo}
          >
            <PlayButton />
          </div>
          <div
            className="max-h-[344px] cursor-pointer"
            onClick={handleToggleShowVideo}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              loading="lazy"
              className="block w-full h-auto"
              src={img}
              srcSet={imgSrcset}
              alt="Pre visualização do video"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Video;
