import styles from './styles.module.css';

export interface ScrollingBarProps {
  children: React.ReactNode;
  className?: string;
  phraseRepeats?: number;
}

const ScrollingBar: React.FC<ScrollingBarProps> = ({
  children,
  className,
  phraseRepeats = 40,
}) => {
  return (
    <div className={className}>
      <div className={styles.tickerDiv}>
        <div className={styles.phrase}>
          {[...Array(phraseRepeats)].map((c, index) => (
            <div className="flex cursor-pointer" key={index}>
              {children}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ScrollingBar;
