import StonecoLogo from '@/assets/icons/stoneLogo/stoneco.svg';

const Stoneco: React.FC = () => {
  return (
    <div className="flex justify-end mb-[24px] h-full">
      <a href="https://investors.stone.co/" aria-label="Stone investidor link">
        <StonecoLogo />
      </a>
    </div>
  );
};

export default Stoneco;
