'use client';

import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import Button, { ButtonProps } from '../../base/ui/Button';

interface FloatingButtonProps {
  isMobileOnly?: boolean;
  button: ButtonProps;
  visibleSizeLimit?: number;
}

export function FloatingCTA({
  isMobileOnly = true,
  button,
  visibleSizeLimit = 480,
}: FloatingButtonProps) {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = useCallback(() => {
    const shouldShowButton = window.scrollY > visibleSizeLimit;
    setIsVisible(shouldShowButton);
  }, [visibleSizeLimit]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    isVisible && (
      <div
        className={classNames(
          'sticky md:right-40 bottom-0 md:bottom-40 z-[50] px-24 py-16 md:p-0 w-full md:!w-auto bg-display-0 md:bg-transparent',
          {
            'md:hidden': isMobileOnly,
          },
        )}
      >
        <Button key={button.label} {...button} />
      </div>
    )
  );
}
