import Link from 'next/link';

import FacebookLogo from '@/assets/icons/socialMedia/facebook2.svg';
import InstagramLogo from '@/assets/icons/socialMedia/instagram2.svg';
import LinkedInLogo from '@/assets/icons/socialMedia/linkedin2.svg';
import TwitterLogo from '@/assets/icons/socialMedia/twitter2.svg';
import YouTubeLogo from '@/assets/icons/socialMedia/youtube2.svg';
import StoneLogo from '@/assets/icons/stoneLogo/stone-logo.svg';

type TLogoAndSocialMedias = {
  isNoMenu?: boolean;
};

const LogoAndSocialMedias: React.FC<TLogoAndSocialMedias> = ({ isNoMenu }) => (
  <div className="flex flex-col md:flex-row my-0 mx-auto md:mt-[8px] md:mb-[48px] md:h-auto">
    <Link
      href="/"
      prefetch={false}
      passHref
      className="flex flex-1 mb-32 md:mb-0"
      aria-label="Link home"
    >
      <StoneLogo className="w-[96px] md:w-[106px] h-24 md:h-[28px]" />
    </Link>
    <div
      className={`${
        isNoMenu && 'hidden'
      } flex flex-1 gap-32 sm:justify-start md:justify-end`}
    >
      <a
        href="https://www.facebook.com/stonepagamentos/"
        aria-label="Facebook link"
      >
        <FacebookLogo />
      </a>
      <a href="https://twitter.com/sejastone/" aria-label="Twitter link">
        <TwitterLogo />
      </a>
      <a href="https://www.instagram.com/stone/" aria-label="Instagram link">
        <InstagramLogo />
      </a>
      <a
        href="https://www.linkedin.com/company/stone-pagamentos/"
        aria-label="Linkedin link"
      >
        <LinkedInLogo />
      </a>
      <a
        href="https://www.youtube.com/channel/UCk4L4JykgoDkN8YeuxkNqIQ"
        aria-label="Youtube link"
      >
        <YouTubeLogo />
      </a>
    </div>
  </div>
);

export default LogoAndSocialMedias;
