'use client';

import { FieldProps, useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import Select, { OptionTypeBase, Props as SelectProps } from 'react-select';

import styles from './FormikSelectInput.module.css';

interface PropsType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  name: string;
}

interface IFormikSelectInput extends SelectProps {
  label: string;
  name: string;
  error?: string;
  description?: string;
  onBlurCallBack?: (option: OptionTypeBase) => void;
}

const FormikSelectInput: React.FC<IFormikSelectInput & FieldProps> = ({
  label,
  description,
  placeholder = '',
  onBlurCallBack,
  ...props
}: PropsType) => {
  const [field, meta, helpers] = useField(props);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { options } = props;
  const { touched, error, value } = meta;
  const { setValue } = helpers;

  const activeLabel = useMemo(() => !!value, [value]);

  useEffect(() => {
    if (value === '') {
      const el = document.getElementsByClassName(
        'react-select-form__single-value',
      )[0];

      if (el) {
        el.innerHTML = '';
      }
    }
  }, [value]);

  return (
    <div
      className={`${styles.selectContainer} ${
        menuIsOpen ? styles.selectContainerOpen : ''
      }`}
    >
      <label
        htmlFor={props.id || props.name}
        className={`${styles.label} ${
          menuIsOpen ? 'text-stone-500 z-30' : 'z-10'
        } ${activeLabel ? styles.labelActive : ''}`}
      >
        {label}
      </label>
      <Select
        id={field.name}
        options={options}
        aria-label={field.name}
        placeholder={placeholder}
        isSearchable={false}
        instanceId={props.iid}
        className={`${styles.select} ${
          !value ? styles.selectWithoutValue : ''
        } ${menuIsOpen ? 'z-20' : ''}`}
        classNamePrefix="react-select-form"
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        onChange={(option: OptionTypeBase) => {
          setValue(option.value);

          if (onBlurCallBack) onBlurCallBack(option);
        }}
        isDisabled={props.isDisabled}
      />
      {description && (
        <p className="mt-4 text-display-700 paragraph-14">{description}</p>
      )}
      {error && touched && (
        <p className="mt-4 text-red-500 paragraph-14">{meta.error}</p>
      )}
    </div>
  );
};

export default FormikSelectInput;
