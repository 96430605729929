'use client';

import dynamic from 'next/dynamic';
import { ComponentType, SVGProps, useMemo } from 'react';

const assets = {
  'green/agent': dynamic(() => import('./assets/green/agent')),
  'green/circle-check': dynamic(() => import('./assets/green/circleCheck')),
  'green/money': dynamic(() => import('./assets/green/money')),
  'green/racional': dynamic(() => import('./assets/green/racional')),
  'green/smile': dynamic(() => import('./assets/green/smile')),
  'green/heart': dynamic(() => import('./assets/green/heart')),
  'black/up-chart': dynamic(() => import('./assets/black/upChart')),
  'black/user': dynamic(() => import('./assets/black/user')),
  'black/shield': dynamic(() => import('./assets/black/shield')),
  'black/phone': dynamic(() => import('./assets/black/phone')),
  'black/boleto': dynamic(() => import('./assets/black/boleto')),
} as const;

export type StonjiNames = keyof typeof assets;

export type StonjiProps = {
  name: StonjiNames;
  className?: string;
};

export default function StonjiIcon({ name, className }: StonjiProps) {
  const CustomStonji: ComponentType<SVGProps<SVGSVGElement>> = useMemo(
    () => assets[name],
    [name],
  );

  return <CustomStonji className={className} />;
}
