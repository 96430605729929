'use client';

import { ImageCMSProps, LinkProps } from '@/types/cms';
import { CMSEventParams, useAnalytics } from '@/app/hooks/useAnalytics';
import Link from 'next/link';
import { useCMSLink } from '@/hooks/useCMSLink';
import { DefaultVariant } from './variants/Default';
import { MasterchefVariant } from './variants/Masterchef';
import { stripHtml } from '@/helpers/html';

export interface ScrollingBarProps {
  referId: string;
  variant: 'default' | 'masterchef';
  phraseRepeats: number;
  firstText: string;
  imageQuality: number;
  secondText?: string;
  image: ImageCMSProps;
  link: LinkProps;
  trackClick: CMSEventParams[];
}

interface Props {
  overwriteLink?: LinkProps;
  collection: ScrollingBarProps;
}

const variants = {
  default: DefaultVariant,
  masterchef: MasterchefVariant,
};

export function ScrollingBar(props: Props) {
  const { variant, trackClick, link, firstText, secondText } = props.collection;

  const { sendEvents } = useAnalytics();
  const { getHrefFromCMSParams } = useCMSLink();

  const Component = variants[variant] || variants.default;

  function onClick() {
    if (!trackClick || trackClick?.length) return;

    const events = trackClick.map(event => ({
      ...event,
      sectionReference: 'banner rotativo',
      ctaReference: stripHtml(`${firstText} ${secondText || ''}`),
    }));

    sendEvents(events);
  }

  return (
    <Link
      href={getHrefFromCMSParams(props?.overwriteLink || link)}
      prefetch={false}
    >
      <section onClick={onClick} id={props.collection.referId}>
        <Component {...props.collection} />
      </section>
    </Link>
  );
}
