'use client';

import { useResponsiveQuery } from 'atomic-layout';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import Icon from '@/components/v3/Icon';
import { personalizationIsNoMenu } from '@/utils/personalization';

import Disclaimer from './components/Disclaimer';
import Links from './components/Links';
import LogoAndSocialMedias from './components/LogoAndSocialMedias';
import Products from './components/Products';
import Stoneco from './components/Stoneco';
import { linkItems, linkItemsNoMenu } from './resources/links';

export function Footer({
  referId,
  isSimpleFooter = false,
}: {
  referId?: string;
  isSimpleFooter?: boolean;
}) {
  const pathname = usePathname() || '/';
  const isNoMenu = personalizationIsNoMenu(pathname);
  const isMobile = useResponsiveQuery({ to: 'md' });

  return (
    <>
      {isSimpleFooter ? (
        <SimpleFooter />
      ) : (
        <div
          id={referId}
          className="py-[32px] px-[24px] text-display-0 bg-display-900"
        >
          <div className="my-0 mx-auto max-w-[1324px]">
            <LogoAndSocialMedias isNoMenu={isNoMenu} />
            {isMobile ? (
              <Links linkItems={linkItems} />
            ) : (
              <Links linkItems={isNoMenu ? linkItemsNoMenu : linkItems} />
            )}
            <Disclaimer />
            <Stoneco />
            {!isNoMenu && <Products />}
          </div>
        </div>
      )}
    </>
  );
}

function SimpleFooter() {
  return (
    <>
      <div
        id="Context_footer"
        className="py-40 px-[24px] text-display-0 bg-display-900"
      >
        <div className="flex flex-col gap-24 my-0 mx-auto max-w-[1324px]">
          <div className="flex flex-col md:flex-row">
            <Link
              href="/"
              prefetch={false}
              passHref
              className="flex flex-1"
              aria-label="Link home"
            >
              <Icon
                name="logo"
                className="w-[96px] md:w-[106px] h-24 md:h-[28px] text-stone-500 fill-current"
              />
            </Link>
          </div>
          <Disclaimer isSimpleFooter />
        </div>
      </div>
    </>
  );
}
