'use client';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import styles from './StoneMode.module.css';
import { useResponsiveQuery } from 'atomic-layout';

const lastAnimation = 7;

type Props = {
  className?: string;
  inView?: boolean;
};

const StoneMode: React.FC<Props> = ({ className, inView }) => {
  const [animation, setAnimation] = useState<number>(0);
  const isMobile = useResponsiveQuery({ to: 'lg' });

  useEffect(() => {
    const interval = setInterval(() => {
      return setAnimation(prev => (prev === lastAnimation ? 1 : prev + 1));
    }, 700);
    return () => clearInterval(interval);
  }, [animation]);

  function anim(current: number[], classes: string) {
    return current.includes(animation) ? classes : '';
  }

  const size = isMobile ? 64 : 84;

  return (
    <div
      className={`flex justify-center items-center py-16 px-24 lg:p-24 m-auto w-max rounded-full border-2 border-solid ${className} relative`}
      style={{
        borderColor: inView ? '#42EC9A' : '#0FCC7D',
        minWidth: size,
        minHeight: size,
      }}
    >
      <p
        className={classNames(
          anim([4, 5, 6, 7], styles.FullText),
          anim([0, 1, 2, 3], styles.MidText),
          anim([0, 1, 2], styles.NoWidth),
          `flex font-display text-2xl lg:text-3xl font-bold  overflow-hidden`,
          inView ? 'text-display-0' : 'text-display-600',
        )}
      >
        Modo Stone{' '}
        <span
          className={classNames(
            `ml-8 overflow-hidden`,
            inView ? 'text-stone-300' : 'text-stone-400',
            styles.Active,
            anim([0, 1, 2, 3], styles.NoWidth),
          )}
        >
          Ativado
        </span>
      </p>
      <div
        className={classNames(
          `ml-10 rounded-full`,
          inView ? 'bg-stone-300' : 'bg-stone-400',
          styles.GreenCircle,
          anim([2], styles.GreenCircleBig),
          anim([0, 1, 2], styles.NoMargin),
          anim([3, 4, 5, 6, 7], styles.AlignRight),
        )}
      ></div>
    </div>
  );
};

export default StoneMode;
