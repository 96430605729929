'use client';

import clsx from 'clsx';
import React, { RefObject, useCallback, useContext, useState } from 'react';

import { AmplitudeContext } from '@/contexts/amplitude';

export interface IFaqItem {
  question: string;
  answer: string;
  itemsLength: number;
  currentItemPosition: number;
  show?: boolean;
  ref?: RefObject<HTMLLIElement> | null;
}

const FaqItem: React.FC<IFaqItem> = ({
  question,
  answer,
  itemsLength,
  currentItemPosition,
  show,
  ref,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { analytics } = useContext(AmplitudeContext);

  const handleClick = useCallback(() => {
    setIsOpen(value => !value);
  }, []);

  return (
    <li
      ref={ref}
      className={`flex overflow-hidden relative flex-col p-16 ${
        currentItemPosition === itemsLength ? '' : 'mb-8 md:mb-16'
      } w-full lg:max-w-[785px] bg-display-0 rounded-[8px] lg:rounded-[16px] border-[2px] border-display-200 border-solid ${
        show ? 'block' : 'hidden'
      }`}
      onClick={() => {
        !isOpen
          ? analytics?.track({
              event_type: 'cta stone',
              event_properties: {
                name: 'cta stone',
                description: 'Evento disparado no clique do cta de duvidas',
                section_reference: 'Tire todas as suas dúvidas',
                cta_reference: `${question}`,
              },
            })
          : null;
      }}
    >
      <div
        onClick={handleClick}
        className="flex justify-between items-center cursor-pointer"
      >
        <p className="font-medium paragraph-18 lg:paragraph-20">{question}</p>
        <button
          className={`
        select-none
        relative ml-8 flex h-40 min-h-[40px] w-40 min-w-[40px] cursor-pointer
        items-center justify-center whitespace-normal rounded-[50%] bg-display-0 p-0 font-medium text-stone-500 no-underline outline-none
        before:absolute before:h-[21px] before:w-2 before:bg-stone-500 before:transition-all before:duration-[150ms]
        after:absolute after:h-2 after:w-[21px] after:bg-stone-500
        sm:w-[fit-content] 
        ${isOpen ? 'before:rotate-90' : ''}`}
        />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: answer }}
        className={clsx(
          `grid overflow-hidden gap-8 max-h-0 font-normal transition-all duration-[150ms]`,
          isOpen && 'mt-16 max-h-full',
        )}
      />
    </li>
  );
};

export default FaqItem;
