/* eslint-disable tailwindcss/no-custom-classname */

'use client';

import 'keen-slider/keen-slider.min.css';

import classNames from 'classnames';
import { useKeenSlider } from 'keen-slider-legacy/react';
import Image from 'next/image';
import { useState } from 'react';

import Icon from '@/components/v3/Icon';
import { tabScrollToCenter } from '@/services/tabScrollToCenter';
import { ImageResponsiveCMSProps } from '@/types/cms';
import Button, { ButtonProps } from '../../base/ui/Button';

// TODO: refactor: da pra refazer esse componente sem nenhuma lib, porque não precisa de nada do slider e melhoraria performance

interface TabsProps {
  tag: string;
  title: string;
  description: string;
  label: string;
  buttons: ButtonProps[];
  image: ImageResponsiveCMSProps;
}

export interface TabCardsProps {
  id: string;
  title: string;
  subtitle: string;
  tabs: TabsProps[];
}

type TTabsClassNames = {
  containerClassName?: string;
};
export function TabsCards({
  id,
  title,
  subtitle,
  tabs,
  containerClassName,
}: TabCardsProps & TTabsClassNames) {
  const [currentOpacities, setCurrentOpacities] = useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slides: tabs.length,
    initial: 0,
    centered: true,
    loop: false,
    controls: false,
    duration: 0,
    vertical: false,
    spacing: 40,
    move(s) {
      const newOpacities = s.details().positions.map(slide => slide.portion);
      setCurrentSlide(s.details().relativeSlide);
      setCurrentOpacities(newOpacities);
      tabScrollToCenter(
        s.details().relativeSlide,
        '#tabsContainerId',
        '.tabs-wrapper',
      );
    },
  });

  return (
    <div
      id="totalmente-integrado"
      className={`flex flex-col justify-center items-center my-40 md:my-80 lg:mx-24 xl:mx-0 ${containerClassName}`}
    >
      <h2 className="mx-24 md:mx-auto lg:mx-24 mb-16 md:mb-8 max-w-[455px] md:max-w-none font-display md:text-3xl lg:text-4xl text-center heading-3">
        {title}
      </h2>
      {subtitle && (
        <p className="mb-16 md:mb-24 text-lg lg:text-xl text-center text-display-900">
          {subtitle}
        </p>
      )}
      <div className="flex md:hidden flex-col">
        <div className="flex overflow-hidden mt-16 w-full max-w-[455px] h-full min-h-[240px] rounded-t-2xl">
          <Image
            src={tabs[currentSlide].image.mobile.path}
            style={{ objectFit: 'cover' }}
            alt={tabs[currentSlide].image.mobile.alternativeText}
            quality={tabs[currentSlide].image.mobileQuality || 70}
            width={380}
            height={240}
          />
        </div>
      </div>
      <div className="flex justify-center w-full">
        <div className="flex w-full md:bg-transparent">
          <div
            id={id}
            className="flex overflow-x-auto items-center md:px-24 mx-auto md:mb-24 w-max"
          >
            {tabs.map(({ label }, index) => (
              <div
                key={label}
                className="flex justify-center items-center last:pr-8 first:pl-8 h-56 md:h-[72px] bg-display-100 md:last:rounded-r-[40px] md:first:rounded-l-[40px] tabs-wrapper"
                data-tab-index={index}
              >
                <div
                  className={classNames(
                    'p-8 rounded-[40px] h-40 md:p-16 md:h-[56px] flex justify-center items-center',
                    {
                      'bg-display-0': currentSlide === index,
                    },
                  )}
                >
                  <button
                    key={label}
                    type="button"
                    data-index={label}
                    aria-label={label}
                    onClick={() => {
                      instanceRef.moveToSlide(index);
                    }}
                    id={`fazer-negocio-girar-${index}`}
                  >
                    <h3
                      className={classNames(
                        'whitespace-nowrap h-40 text-[16px] font-semibold text-display-600',
                        {
                          'text-stone-500': currentSlide === index,
                        },
                      )}
                    >
                      {label}
                    </h3>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        ref={sliderRef}
        className="md:overflow-hidden relative mt-24 md:mt-0 w-full max-w-full md:max-w-[1060px] h-[280px] md:h-[344px] lg:h-[422px] fader"
      >
        {tabs.map(
          ({ image, description, title, buttons, tag, label }, index) => (
            <div
              key={`card - ${title}`}
              className={classNames(
                `fader__slide absolute w-full h-full flex items-stretch md:px-24 lg:px-0`,
                {
                  [`number-slide-${index + 1}`]: index,
                },
              )}
              style={{ opacity: currentOpacities[index] }}
            >
              <div className="flex overflow-hidden md:relative flex-col md:flex-row items-center md:p-24 lg:px-40 w-full md:rounded-2xl lg:rounded-6xl">
                <div className="hidden md:flex lg:hidden md:absolute right-0 bottom-0 z-0">
                  <Image
                    src={image.tablet.path}
                    style={{ objectFit: 'cover' }}
                    alt={image.tablet.alternativeText}
                    quality={image.tabletQuality || 70}
                    width={768}
                    height={374}
                  />
                </div>

                <div className="hidden lg:flex md:absolute inset-0 z-0">
                  <Image
                    src={image.web.path}
                    style={{ objectFit: 'cover' }}
                    alt={image.web.alternativeText}
                    quality={image.webQuality || 70}
                    fill
                  />
                </div>

                <div
                  key={`${title}-${description}`}
                  className="z-10 md:p-[23px] lg:p-40 px-24 md:mt-0 w-full max-w-[455px] md:max-w-[362px] lg:max-w-[455px] text-left md:bg-display-0 md:rounded-md lg:rounded-2xl"
                >
                  <p
                    className="block mb-16 font-bold text-display-600 uppercase paragraph-14 lg:paragraph-16"
                    dangerouslySetInnerHTML={{ __html: tag }}
                  />

                  <h4
                    className="mb-8 font-display text-2xl md:leading-[32px] text-display-900 heading-4 lg:heading-4"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />

                  <p
                    className="mb-16 text-display-900 paragraph-16 lg:paragraph-18"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />

                  <div className="w-full md:w-max">
                    {buttons?.map(button => (
                      <Button {...button} label={button.label || label} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ),
        )}
      </div>

      <div className="flex md:hidden justify-center w-full bg-transparent">
        <button
          type="button"
          className="mr-6 w-40 h-40 cursor-pointer"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any) => e.stopPropagation() || instanceRef.prev()}
          disabled={currentSlide === 0}
          aria-label="Voltar para aba anterior"
        >
          <Icon
            name="arrow-left-2x"
            className={classNames(
              `h-40 w-40 fill-current text-stone-500 md:h-48 md:w-48`,
              {
                'text-display-400': currentSlide === 0,
              },
            )}
          />
        </button>

        <button
          type="button"
          className="ml-6 w-40 h-40 cursor-pointer"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any) => e.stopPropagation() || instanceRef.next()}
          disabled={currentSlide === tabs.length - 1}
          aria-label="Ir para próxima aba"
        >
          <Icon
            name="arrow-right-2x"
            className={classNames(
              `h-40 w-40 fill-current text-stone-500 md:h-48 md:w-48`,
              {
                'text-display-400': currentSlide === tabs.length - 1,
              },
            )}
          />
        </button>
      </div>
    </div>
  );
}
