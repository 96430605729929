'use client';

import { useCallback, useRef, useState } from 'react';
import Image from 'next/image';
import { Simple9x16VideoProps } from '.';
import Icon from '@/components/v3/Icon';
import Modal from '@/components/v3/Modal';
import { useResponsiveQuery } from 'atomic-layout';
import { YouTubeProps } from 'react-youtube';
import dynamic from 'next/dynamic';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const YouTube = dynamic<any | YouTubeProps>(() => import('react-youtube'), {
  ssr: false,
});

import styles from './Video.module.css';

interface Player {
  playVideo: () => void;
}

export function Video({
  thumbnail,
  thumbnailQuality,
  isLCP,
  videoUrl,
}: Simple9x16VideoProps) {
  const isMobile = useResponsiveQuery({ to: 'sm' });
  const playerRef = useRef<Player | null>(null);

  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const closeVideo = useCallback(() => {
    if (!isMobile && alreadyPlayed) {
      setAlreadyPlayed(false);
    }
  }, [alreadyPlayed, isMobile]);

  const youtubeVideoId = videoUrl.includes('https://youtu.be/')
    ? videoUrl.replace('https://youtu.be/', '')
    : videoUrl;

  const playVideo = useCallback(() => {
    if (isMobile) {
      playerRef?.current?.playVideo();
    }

    setAlreadyPlayed(true);
  }, [isMobile]);

  function togglePlay() {
    return alreadyPlayed ? closeVideo() : playVideo();
  }

  return (
    <div>
      <div
        className="overflow-hidden relative w-[312px] min-w-[312px] h-[554px] rounded-4xl cursor-pointer"
        onClick={togglePlay}
      >
        {isMobile ? (
          <div className={styles.YoutubeContainer}>
            <YouTube
              videoId={youtubeVideoId}
              opts={{ playerVars: { autoplay: 0, rel: 0 } }}
              onReady={(e: { target: Player }) => {
                playerRef.current = e.target;
              }}
            />
          </div>
        ) : (
          <Modal
            open={alreadyPlayed}
            onCloseClick={closeVideo}
            closeOnClickOut={true}
            data-testid="hero-modal"
            className="h-full bg-transparent"
          >
            <div className="mx-auto w-full max-w-max h-full">
              <div className="flex">
                <button type="button" onClick={closeVideo} className="ml-auto">
                  <Icon name="close" className="text-display-0 fill-current" />
                </button>
              </div>
              <div className="flex flex-col gap-16 w-full h-full md:h-[90%]">
                <div className={styles.YoutubeContainer}>
                  {alreadyPlayed && (
                    <YouTube
                      videoId={youtubeVideoId}
                      className="w-full lg:min-w-[448px] h-full"
                      opts={{ playerVars: { autoplay: 1, rel: 0 } }}
                      onEnd={() => {
                        setAlreadyPlayed(false);
                      }}
                      autoplay
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}

        {!alreadyPlayed && (
          <>
            {thumbnail && (
              <Image
                src={thumbnail.path}
                alt={thumbnail.alternativeText}
                style={{ objectFit: 'cover' }}
                layout="fill"
                priority={isLCP || false}
                quality={thumbnailQuality || 70}
              />
            )}

            <div className="flex absolute top-0 left-0 z-10 justify-center items-center w-full h-full">
              <div className="flex justify-center items-center p-8 w-[100px] h-[100px] bg-gray-800 bg-opacity-60 rounded-full">
                <Icon
                  name="play"
                  className="w-[57px] h-[57px] text-display-0 fill-current"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
