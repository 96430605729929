import { BlackFridayHero } from '@/app/components/sections/BlackFridayHero';
import { DefaultHero } from '@/app/components/sections/DefaultHero';

export const slidesMap = {
  'dynamics.default-hero': DefaultHero,
  'dynamics.black-friday-hero': BlackFridayHero,
};

import {
  Fallback,
  SectionProps,
  SectionRules,
} from '../../base/cms/Section/SectionRules';
import { memo } from 'react';

type TypedSlideIds = keyof typeof slidesMap;

export const Slide = memo(function Slide(props: SectionProps) {
  const component = (slidesMap[props.name as TypedSlideIds] ||
    Fallback) as unknown as React.FC<SectionProps>;

  return <SectionRules component={component} props={props} />;
});
