import IS_SERVER from '@/constants/isServer';
import { getUrlParams } from '@/utils/urlParams';

export const getCouponParam = () => {
  const couponField = getUrlParams().find(param => param.name === 'coupon');
  let coupon = couponField?.value;
  if (coupon) return `&coupon=${coupon}`;

  if (IS_SERVER) return '';

  const storedData = sessionStorage?.getItem('auto-cred');
  if (!storedData) return '';

  coupon = JSON.parse(storedData)?.state?.coupon;

  return coupon ? `&coupon=${coupon}` : '';
};
