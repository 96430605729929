'use client';

import ExclusiveOffersForm from '@/components/ExclusiveOffersForm';
import Modal from '@/components/v3/Modal';
import LikeCopy from '@/assets/illustrations/like-copy.svg';
import { useState } from 'react';
import Button from '../../base/ui/Button';

export function Items({
  title,
  description,
  buttonLabel,
}: {
  title: string;
  description: string;
  buttonLabel: string;
}) {
  const [secondStep, setSecondStep] = useState(false);

  const closeFirstModal = () => {
    setSecondStep(true);
  };

  return (
    <>
      <Modal
        open={secondStep}
        onCloseClick={() => null}
        className="flex md:block justify-center items-center md:m-96 w-full md:max-w-[520px] md:h-auto md:max-h-[521px] rounded-none md:rounded"
      >
        <div className="flex flex-row-reverse items-start">
          <button
            type="button"
            onClick={() => setSecondStep(false)}
            className="after:inline-block after:scale-[1.5] cursor-pointer"
            aria-label="fechar"
          ></button>
          <div className="flex flex-col justify-center items-center my-36 text-center">
            <LikeCopy />
            <h5 className="mt-32 mb-8 font-bold">
              Obrigado por nos contar um pouco sobre você
            </h5>
            <p className="mb-32">
              Nosso time entrará em contato com ofertas exclusivas pro seu
              negócio.
            </p>

            <Button
              sectionReference={title}
              color="secondary"
              onClick={() => setSecondStep(false)}
            >
              Voltar para o site
            </Button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-col ">
        <ExclusiveOffersForm
          sectionReference={title}
          textAlign="text-left"
          className="w-full md:w-[60%] xl:w-[50%]"
          title={title}
          description={description}
          button={{
            label: buttonLabel,
            onClick: () => closeFirstModal(),
          }}
          tpvField
        />
      </div>
    </>
  );
}
