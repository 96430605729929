import styles from './styles.module.css';
import MainScrollingBar from '@/components/ScrollingBar/ScrollingBar';
import { ScrollingBarProps } from '../..';
import Image from 'next/image';

export function DefaultVariant({
  firstText,
  image,
  phraseRepeats,
  imageQuality,
}: ScrollingBarProps) {
  return (
    <MainScrollingBar
      phraseRepeats={phraseRepeats}
      className={`${styles.tickerBanner} py-6`}
    >
      <p
        className="p-10 ml-24"
        dangerouslySetInnerHTML={{ __html: firstText }}
      />
      <Image
        src={image.path}
        height={image.height || 0}
        width={image.width || 0}
        alt={image.alternativeText}
        quality={imageQuality || 70}
      />
    </MainScrollingBar>
  );
}
