/* eslint-disable tailwindcss/no-custom-classname */

'use client';

import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import Button from '@/app/components/base/ui/Button';
import TooltipSvg from '@/assets/icons/general/new/infoSvg.svg';
import ButtonOpenYourAccount from '@/components/ButtonOpenYourAccount';
import { Choose, For } from '@/components/UtilitiesComponents';
import LINKS from '@/constants/links';
import { useMgm } from '@/hooks/useMgm';
import { coinFormatter } from '@/utils/formatters';

import { Extras, IPlan, IPlanCondition } from '../../planType';
import styles from './PlanDetails.module.css';

interface IPlanDetails extends IPlan {
  title: string;
  isOpen: boolean;
  handleCloseModal: () => void;
  submit?: () => void;
  children?: React.ReactNode;
}

interface IConditionDetails {
  domicile: string;
  ravType: string;
  acquisition: string;
  agreement: string;
}

enum VendaPix {
  pos = 'Pix na maquininha',
  app = 'Pix no App',
}

enum VendaPixAVista {
  pos_for_first_month = 'Pix na maquininha no 1° mês',
  pos_after_first_month = 'Pix na maquininha após o 1º mês',
  app = 'Pix no App',
}

const listTable = [
  {
    offerActive: 'Grátis por 3 meses',
    offerInactive: 'R$ 1,99 por boleto',
    name: 'Boletos Pagos',
  },
  {
    offerActive: '0% de taxa',
    offerInactive: '3,45% de taxa',
    name: 'Pix em lote',
  },
];

const whatsappMessage =
  'Protocolo%20de%20Atendimento:%20CONS3011%20Oi!%20Eu%20gostaria%20de%20adquirir%20uma%20maquininha.%20Voc%C3%AA%20pode%20me%20ajudar?';

const PlanDetails: React.FC<IPlanDetails> = props => {
  const pathname = usePathname() || '/';
  const [selectedFlag, setSelectedFlag] = useState('master');
  const [active, setActive] = useState(false);
  const [conditions, setConditions] = useState<IConditionDetails>(
    {} as IConditionDetails,
  );
  const [isMgm] = useMgm();
  const isPlanDetailsPJ = process.env.IS_PLAN_DETAILS_ACCOUNT_PJ === 'true';

  useEffect(() => {
    const domicile =
      props.conditions?.find(condDetail => condDetail.name === 'domicile') ||
      ({} as IPlanCondition);
    const ravType =
      props.conditions?.find(condDetail => condDetail.name === 'ravType') ||
      ({} as IPlanCondition);
    const acquisition =
      props.conditions?.find(condDetail => condDetail.name === 'acquisition') ||
      ({} as IPlanCondition);
    const agreement =
      props.conditions?.find(condDetail => condDetail.name === 'agreement') ||
      ({} as IPlanCondition);

    const condDetail: IConditionDetails = {
      domicile: domicile.description,
      ravType: ravType.description,
      acquisition: acquisition.description,
      agreement: agreement.description,
    };

    setConditions(condDetail);
  }, [props]);

  const feesList: string[][] = [['master', 'visa'], ['elo'], ['hiper', 'amex']];
  const general = props?.extras?.find(
    extra => extra.Geral !== undefined,
  )?.Geral;
  const transfer = props?.extras?.find(
    extra => extra['Transfêrencias e Saques'] !== undefined,
  )?.['Transfêrencias e Saques'];
  const pix = props?.extras?.find(extra => extra.Pix !== undefined)?.Pix;
  const boletos = props?.extras?.find(
    extra => extra.Boleto !== undefined,
  )?.Boleto;
  const paymentLink = props?.extras?.find(
    extra => extra['Link de Pagamento'] !== undefined,
  )?.['Link de Pagamento'];

  useEffect(() => {
    const tableContainer = document.querySelector('.overflow-x-scroll');

    const handleScroll = () => {
      if (tableContainer) {
        if (tableContainer.scrollLeft > 4) {
          setActive(true);
        } else {
          setActive(false);
        }
      }
    };
    tableContainer?.addEventListener('scroll', handleScroll);

    return () => {
      tableContainer?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isFlexPlan = props.plan_name === 'Modo Flex';

  return (
    <section
      className={`${styles.planDetailsContainer} md:max-w-[795px] md:m-auto`}
    >
      <div
        data-section-id=""
        className="flex justify-between items-center w-full"
      >
        <p className="font-display heading-4">{props.title}</p>
        <button
          type="button"
          onClick={props.handleCloseModal}
          className='after:inline-block bg-white after:scale-[1.5] cursor-pointer after:content-["\00d7"]'
        ></button>
      </div>
      <div className="overflow-y-auto pr-8 h-auto">
        <div className="max-h-[75vh]">
          <div className={`md:flex-row flex flex-col md:gap-20`}>
            <div className={`flex flex-col md:w-1/2`}>
              <div data-section-id="recomendado">
                <p className="mt-20 font-display heading-6">Recomendado para</p>
                <div className="mt-8">
                  <div className="p-16 rounded border border-display-400 border-solid">
                    <p className={` paragraph-14 text-display-700`}>
                      {props.plan_name === 'Modo Conta PJ'
                        ? props.extras && props.extras[0]?.['Recomendado Para']
                        : props.target_client}
                    </p>
                  </div>
                </div>
              </div>
              {props.conditions && !!props.conditions.length && (
                <div data-section-id="conditions">
                  <p className="mt-20 mb-8 font-display heading-6">Condições</p>
                  <div className="flex flex-col px-16 rounded border border-display-400 border-solid">
                    <div className="flex justify-between py-16">
                      <p className="paragraph-14">Onde recebe</p>
                      <p className="font-medium paragraph-14">
                        {conditions.domicile || ''}
                      </p>
                    </div>
                    <div className="flex justify-between py-16 border-t border-display-400">
                      <p className="flex gap-6 items-center paragraph-14">
                        Quando recebe
                        {!isFlexPlan && (
                          <span
                            aria-label="Seus recebimentos chegam sempre no dia seguinte ao dia da venda."
                            data-balloon-pos="down"
                            data-balloon-length="medium"
                            className="tooltip-rounded"
                          >
                            <TooltipSvg className="stroke-[#67707D]" />
                          </span>
                        )}
                      </p>
                      <p className="font-medium paragraph-14">
                        {conditions.ravType || ''}
                      </p>
                    </div>
                    <div className="flex justify-between py-16 border-t border-display-400 ">
                      <p className="paragraph-14">Perfil de contrato</p>
                      <p className="font-medium paragraph-14">
                        {conditions.agreement || ''}
                      </p>
                    </div>
                    <div className="flex justify-between py-16 border-t border-display-400">
                      <p className="flex gap-6 items-center paragraph-14">
                        Modelo de contratação
                        {!isFlexPlan && (
                          <span
                            aria-label="Comodato é um tipo de empréstimo: você usa a maquininha e todos os seus benefícios enquanto tiver o plano. Cancelando o plano, você devolve a maquininha para a Stone."
                            data-balloon-pos="down"
                            data-balloon-length="large"
                            className="tooltip-rounded"
                          >
                            <TooltipSvg className="stroke-[#67707D]" />
                          </span>
                        )}
                      </p>
                      <p className="font-medium paragraph-14">
                        {conditions.acquisition || ''}
                      </p>
                    </div>
                    {!props.installments?.discount && (
                      <div className="flex justify-between py-16 border-t border-display-400">
                        <p className="paragraph-14">Valor a partir de</p>
                        <p className="font-medium paragraph-14">
                          {props.installments?.quantity}x{' '}
                          {coinFormatter(props.installments?.value / 100 || 0)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {general && !!general.length && (
                <div data-section-id="conditions">
                  <p className="mt-20 mb-8 font-display heading-6">Geral</p>
                  <div className="flex flex-col px-16 rounded border border-display-400 border-solid">
                    {general?.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-between py-16 ${
                          index !== general.length - 1 &&
                          'border-b border-display-400 border-solid'
                        }`}
                      >
                        <p className="paragraph-14">{Object.keys(item)[0]}</p>
                        <p className="font-medium paragraph-14">
                          {Object.values(item)[0]}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {transfer && !!transfer.length && (
                <div data-section-id="conditions">
                  <p className="mt-20 mb-8 font-display heading-6">
                    Transferências e saques
                  </p>
                  <div className="flex flex-col px-16 rounded border border-display-400 border-solid">
                    {transfer?.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-between py-16 ${
                          index !== transfer?.length - 1 &&
                          'border-b border-display-400 border-solid'
                        }`}
                      >
                        <p className="paragraph-14">{Object.keys(item)[0]}</p>
                        <p className="font-medium paragraph-14">
                          {Object.values(item)[0]}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {props.pix_fees && !!props.pix_fees.length && (
                <div className="flex flex-col md:flex-1">
                  {!Boolean(props.plan_name === 'Modo Flex') && (
                    <>
                      <p className="mt-20 mb-8 font-display heading-6">
                        Vendas por Pix
                      </p>
                      <div className="px-16 rounded border border-display-400 border-solid">
                        {props.pix_fees?.map((item, index) => (
                          <div
                            key={item.name}
                            className={`flex justify-between py-16 ${
                              index != props.pix_fees.length - 1 &&
                              'border-b border-display-400'
                            }`}
                          >
                            <p className="paragraph-14">
                              {props.offer_name == 'Modo a Vista'
                                ? VendaPixAVista[
                                    item.name as keyof typeof VendaPixAVista
                                  ] || item.name
                                : VendaPix[
                                    item.name as keyof typeof VendaPix
                                  ] || item.name}
                            </p>
                            <p className="font-medium paragraph-14">
                              {item.name === 'pos_for_first_month'
                                ? 'Grátis'
                                : item.value}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {props.products && !!props.products.length && (
              <div className="flex overflow-y-auto flex-col md:w-1/2">
                <div data-section-id="fees">
                  <p className="mt-20 mb-8 font-display heading-6">
                    Taxas por bandeira
                  </p>

                  <div data-section-id="bandeiras">
                    <div className="flex overflow-x-auto justify-center rounded-t-[8px] border border-b-0 border-solid border-display-40">
                      <div className="flex gap-8 p-16">
                        {props.products &&
                          !!props.products.length &&
                          feesList.map((fees, index) => (
                            <button
                              key={index}
                              type="button"
                              className={`flex items-stretch gap-8 p-8 capitalize cursor-pointer rounded  border border-solid border-display-400 bg-display-0 ${
                                fees[0] === selectedFlag &&
                                'border-stone-600 !bg-stone-50'
                              }`}
                              onClick={() => setSelectedFlag(fees[0])}
                            >
                              {fees.map((fee, index) => (
                                <Image
                                  key={index}
                                  src={`/v1/site-stone/planos/${fee}`}
                                  width={36}
                                  height={24}
                                  alt={fee}
                                />
                              ))}
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div
                    data-section-id="taxas"
                    className="overflow-y-auto px-16 md:h-full md:max-h-[500px] rounded-b-[8px] border border-display-400 border-solid"
                  >
                    {props.products &&
                      !!props.products.length &&
                      props.products
                        .find(p => p.name === selectedFlag)
                        ?.prices?.map((item, index) => {
                          return (
                            <div
                              key={item.description}
                              className={`flex justify-between py-16 
                              ${index !== 0 && 'border-t border-display-400'}`}
                            >
                              <p className="paragraph-14 ">
                                {item.description}
                              </p>
                              <p className="font-medium paragraph-14">
                                {item.value}
                              </p>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            )}
            {props.plan_name === 'Modo Flex' && (
              <div className="flex flex-col md:w-1/2">
                {props.extras && (
                  <h6 className="mt-20 mb-8 font-display font-bold heading-6">
                    {
                      Object.keys(
                        props.extras?.[1].details as Extras['details'],
                      )[0]
                    }
                  </h6>
                )}
                <div className="flex flex-col gap-8 p-16 rounded border border-display-400 border-solid">
                  <p
                    className="paragraph-14"
                    dangerouslySetInnerHTML={{
                      __html: props.extras?.[1].details?.[
                        'Isenção por volume de vendas'
                      ] as string,
                    }}
                  />
                </div>
              </div>
            )}
            {props?.extras && !!props?.extras.length && (
              <div className="flex overflow-y-auto flex-col md:flex-1">
                {pix && !!pix.length && (
                  <div data-section-id="fees">
                    <p className="mt-20 mb-8 font-display heading-6">Pix</p>
                    <div className="flex flex-col px-16 rounded border border-display-400 border-solid">
                      {pix?.map((item, index) => (
                        <div
                          key={index}
                          className={`flex justify-between py-16 ${
                            index !== pix.length - 1 &&
                            'border-b border-display-400'
                          }`}
                        >
                          <p className="paragraph-14">{Object.keys(item)[0]}</p>
                          <p className="font-medium paragraph-14">
                            {Object.values(item)[0]}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {boletos && !!boletos.length && (
                  <div className="flex overflow-y-auto flex-col md:flex-1">
                    <div data-section-id="fees">
                      <p className="mt-20 mb-8 font-display heading-6">
                        Boleto
                      </p>
                      <div className="flex flex-col px-16 rounded border border-display-400 border-solid">
                        {boletos?.map((item, index) => (
                          <div
                            key={index}
                            className={`flex justify-between py-16 ${
                              index !== boletos.length - 1 &&
                              'border-b border-display-400'
                            }`}
                          >
                            <p className="paragraph-14">
                              {Object.keys(item)[0]}
                            </p>
                            <p className="font-medium paragraph-14">
                              {Object.values(item)[0]}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {paymentLink && !!paymentLink.length && (
                  <div className="flex flex-col md:flex-1">
                    <div data-section-id="fees">
                      <p className="mt-20 mb-8 font-display heading-6">
                        Link de Pagamento
                      </p>
                      <div className="flex flex-col px-16 rounded border border-display-400 border-solid">
                        {paymentLink?.map((item, index) => (
                          <div
                            key={index}
                            className={`flex justify-between py-16 ${
                              index !== paymentLink.length - 1 &&
                              'border-b border-display-400'
                            }`}
                          >
                            <p className="paragraph-14">
                              {Object.keys(item)[0]}
                            </p>
                            <p className="font-medium paragraph-14">
                              {Object.values(item)[0]}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {props.plan_name === 'Modo Conta PJ' && isPlanDetailsPJ && (
            <div className="mt-[20px] lg:mt-[24px]">
              <p className="font-display text-xl font-bold leading-5">
                Condições especiais da oferta
              </p>
              <p className="my-8 text-sm leading-3 text-display-700">
                Ofertas válidas pra novos clientes que cadastrarem o CNPJ como
                chave pix na Conta PJ Stone
              </p>
              <div
                className={`overflow-x-scroll w-auto rounded border border-solid border-display-400 ${styles.scrollTable}`}
              >
                <table className="row-auto w-full min-w-[400px] text-sm text-left rtl:text-right rounded border-solid border-collapse table-fixed">
                  <thead className="font-sharon text-sm font-medium leading-3 bg-display-100">
                    <tr>
                      <th
                        className={`sticky left-0 py-16 bg-display-100 `}
                      ></th>
                      <th className="py-16">Oferta ativa</th>
                      <th className="py-16">Oferta inativa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <For
                      of={listTable}
                      render={(item, index) => (
                        <tr key={index}>
                          <td className="sticky left-0 bg-white">
                            <div
                              className={`py-16 border-t border-display-400 text-sm leading-3 text-display-700 whitespace-nowrap 
                          ${active ? styles.box : ''} ${
                                index !== 0 ? 'ml-16' : 'pl-16'
                              }`}
                            >
                              {item.name}
                            </div>
                          </td>
                          <td>
                            <div className="py-16 text-sm font-medium leading-3 text-stone-500 whitespace-nowrap border-t border-display-400">
                              {item.offerActive}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`py-16 border-t border-display-400 text-sm font-medium leading-3 whitespace-nowrap ${
                                index !== 0 ? 'mr-16' : ''
                              }`}
                            >
                              {item.offerInactive}
                            </div>
                          </td>
                        </tr>
                      )}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end w-full">
        <Choose>
          <Choose.When condition={props.plan_name === 'Modo Conta PJ'}>
            <ButtonOpenYourAccount
              className="mt-[20px] lg:mt-[24px] w-max"
              qrCodeLink={
                isMgm
                  ? LINKS.APP_LINK_APPSFLYER_QR_CODE_PLANOS_MGM
                  : LINKS.APP_LINK_APPSFLYER_QR_CODE_PLANOS
              }
            >
              Escolher {props.title}
            </ButtonOpenYourAccount>
          </Choose.When>

          <Choose.Otherwise>
            <Button
              className="mt-[20px]"
              onClick={
                isFlexPlan
                  ? pathname === '/produtos-stone/'
                    ? () => {
                        window.open(
                          `https://api.whatsapp.com/send?phone=552140421163&text=${whatsappMessage}`,
                          '_blank',
                        );
                      }
                    : props.submit
                  : props.submit
              }
              data-testid="button"
              sectionReference={`Modal de detalhes do plano ${props.title}`}
            >
              {isFlexPlan ? 'Conversar com a Stone' : `Escolher ${props.title}`}
            </Button>
          </Choose.Otherwise>
        </Choose>
      </div>
    </section>
  );
};

export default PlanDetails;
