import Image from 'next/image';
import { ScrollingBarProps } from '../..';
import styles from './styles.module.css';
import MainScrollingBar from '@/components/ScrollingBar/ScrollingBar';

export function MasterchefVariant({
  firstText,
  secondText,
  image,
  phraseRepeats,
  imageQuality,
}: ScrollingBarProps) {
  return (
    <MainScrollingBar className="p-12 bg-black" phraseRepeats={phraseRepeats}>
      <div className="flex gap-8 justify-center items-center">
        <h6
          className="font-display text-lg font-medium text-white"
          dangerouslySetInnerHTML={{ __html: firstText }}
        />
      </div>
      <div className={`${styles.tickerBadge} px-16 mx-40`}>
        {secondText && (
          <p
            className="font-display text-base font-medium text-white"
            dangerouslySetInnerHTML={{ __html: secondText }}
          />
        )}

        <Image
          src={image.path}
          height={image.height || 0}
          width={image.width || 0}
          className="mr-6 ml-10"
          alt={image.alternativeText}
          quality={imageQuality || 70}
        />
      </div>
    </MainScrollingBar>
  );
}
