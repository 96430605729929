import Icon, { IconsNames } from '@/components/v3/Icon';
import { ResponsiveImagesWithAttributes } from '@/types/cms';
import Image from 'next/image';

export type AdvantagesCardProps = {
  responsiveImage: ResponsiveImagesWithAttributes[];
  tag: string;
  title: string;
  iconDescription: {
    iconName: IconsNames;
    description: string;
  }[];
  cardHeight: string;
};

const cardStyles = [
  {
    tag: 'VENDER',
    position:
      'top-[24px] lg:top-[40px] w-full h-[210px] md:h-[200px] lg:h-[320px]',
    cardHeight: 'md:h-[490px] lg:h-[423px]',
  },
  {
    tag: 'GERIR',
    position:
      'top-[18px] lg:top-[30px] w-full h-[200px] md:h-[200px] lg:h-[320px]',
    cardHeight: 'sm:h-[460px] md:h-[488px] lg:h-[420px]',
  },
  {
    tag: 'GIRAR',
    className:
      'p-8 bg-white pt-[7px] rounded-t-2xl border-2 border-b-0 border-display-300',
    cardHeight: 'md:h-[415px]',
  },
] as unknown as Record<string, string>[];

export const AdvantagesCard = ({
  responsiveImage,
  tag,
  title,
  iconDescription,
}: AdvantagesCardProps) => {
  const getPropertyByTag = (property: string) => {
    const item = cardStyles.find(item => item.tag === tag);
    return item && item[property] ? item[property] : null;
  };

  return (
    <div className="overflow-hidden relative w-[312px] lg:w-[400px]">
      <div
        className={`overflow-hidden relative z-30 px-8 pt-32, ${getPropertyByTag(
          'className',
        )}`}
      >
        <Image
          src={responsiveImage[0].image.path}
          alt={responsiveImage[0].image.alternativeText}
          width={responsiveImage[0].image.width ?? 368}
          height={responsiveImage[0].height ?? 231}
          layout="responsive"
        />
      </div>
      <div
        className={`${getPropertyByTag(
          'position',
        )} absolute bg-white rounded-t-2xl border-2 border-b-0 border-display-300`}
      />
      <div
        className={`overflow-hidden p-24 ${getPropertyByTag(
          'cardHeight',
        )} bg-white rounded-3xl rounded-t-none border-2 border-t-0 border-display-300`}
      >
        <div className="inline-block relative py-8 px-16 my-16 mt-0 lg:mb-24 text-sm font-semibold text-stone-700 bg-stone-50 rounded-3xl border-2 border-stone-100">
          {tag}
        </div>
        <div className="md:max-w-[264px] lg:max-w-full">
          <h3 className="mb-16 font-display text-xl lg:text-2xl font-bold leading-5 md:leading-6">
            {title}
          </h3>
          {iconDescription?.map((item, index) => (
            <div
              key={item.description}
              className={`flex gap-8 items-center w-full ${
                iconDescription.length - 1 !== index && 'mb-16'
              }`}
            >
              <div>
                <Icon
                  name={item.iconName}
                  className="text-stone-500 fill-current"
                />
              </div>
              <p className="text-lg leading-[26px]">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
