'use client';

import { useResponsiveQuery } from 'atomic-layout';
import clsx from 'clsx';
import { useContext } from 'react';

import StoneMode from '@/animations/StoneMode';
import StoryVideos, { Story } from '@/components/StoryVideos';
import { AmplitudeContext } from '@/contexts/amplitude';
import {
  trackPartialReproduction,
  trackPlayVideo,
} from '@/helpers/trackPlayVideo';
import { ImageCMSProps } from '@/types/cms';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';

export type HeroProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  activeSectionId?: string;
  title: string;
  description: string;
  videoCard: {
    title: string;
    thumbnail: {
      image: ImageCMSProps;
      description: string;
    };
    videoUrl: string;
  }[];
};

export function HeroVideos({
  referId,
  forwardRef,
  activeSectionId,
  title,
  description,
  videoCard,
}: HeroProps) {
  const isTablet = useResponsiveQuery({ from: 'md' });
  const isDesktop = useResponsiveQuery({ from: 'lg' });
  const { analytics } = useContext(AmplitudeContext);

  return (
    <section id={referId} className="pb-[80px]" ref={forwardRef}>
      <div className="py-40 lg:py-80 px-24 m-auto max-w-[760px] text-center">
        <h1
          className={clsx(
            `font-display text-5xl lg:text-7xl font-black transition-all duration-300 delay-200`,
            activeSectionId === referId ? 'text-display-0' : 'text-display-900',
          )}
        >
          {title}
        </h1>
        <p
          className={clsx(
            `mt-16 font-normal md:font-normal text-lg lg:text-xl leading-5 transition-all duration-300 delay-200`,
            activeSectionId === referId ? 'text-display-0' : 'text-display-900',
          )}
        >
          {description}
        </p>
      </div>

      <StoryVideos className="px-24 pb-40">
        {videoCard.map((item, index) => (
          <Story
            key={index}
            title={item.title}
            isLCP={index === 1 ? isTablet : index === 2 ? isDesktop : true}
            thumbnail={{
              src: item.thumbnail.image.path,
              quality: 100,
              alt: item.thumbnail.description,
            }}
            video={{
              src: item.videoUrl,
            }}
            trackPlayVideo={() => trackPlayVideo(analytics, title, item.title)}
            trackPartialReproduction={(percentage: number) =>
              trackPartialReproduction(analytics, title, item.title, percentage)
            }
          />
        ))}
      </StoryVideos>

      <StoneMode className="mt-40" inView={activeSectionId === referId} />
    </section>
  );
}
