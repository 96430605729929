'use client';

import Image from 'next/image';
import { useState } from 'react';

import { ImageCMSProps } from '@/types/cms';

import styles from './StepwiseImages.module.css';
import Button, { ButtonProps } from '../../base/ui/Button';

interface FeaturesProps {
  title: string;
  description: string;
  image: ImageCMSProps;
}

export interface StepwiseImagesProps {
  referId: string;
  tag?: string;
  title: string;
  features: FeaturesProps[];
  buttons?: ButtonProps[];
}

export function StepwiseImages({
  referId,
  title,
  tag,
  features,
  buttons,
}: StepwiseImagesProps) {
  const [currentListCard, setCurrentListCard] = useState<FeaturesProps>(
    {} as FeaturesProps,
  );

  const listCardItemAction = (
    e: React.MouseEvent,
    card: FeaturesProps,
  ): void => {
    const allListWrapperEl = document.querySelectorAll(
      '[data-card-name="listWrapper"]',
    );

    const targetElement = e.target as HTMLElement;
    const listCardItem = targetElement.closest(
      '[data-card-name="listWrapper"]',
    );

    allListWrapperEl.forEach(list => list.classList.remove('listItemActive'));

    listCardItem && listCardItem.classList.add('listItemActive');

    setCurrentListCard(card);
  };

  return (
    <div
      id={referId}
      className="flex flex-col justify-center md:justify-start items-center py-40 lg:py-80 md:px-24 mx-auto max-w-[455px] md:max-w-[1108px]"
    >
      <div className="flex relative lg:gap-40 justify-center lg:justify-start items-start mx-auto md:mx-24 lg:mx-0 md:w-full max-w-[455px] md:max-w-full">
        <div className="w-full md:max-w-[510px]">
          <div className="flex flex-col md:justify-center px-16 lg:px-0 w-full md:text-center lg:text-left">
            {tag && (
              <p
                className="mb-8 md:mb-16 w-full max-w-[455px] md:max-w-full font-bold text-stone-700 uppercase paragraph-14"
                dangerouslySetInnerHTML={{
                  __html: tag,
                }}
              />
            )}
            <h2
              className="mb-16 w-full max-w-[510px] font-display text-[32px] md:text-3xl lg:text-[32px] leading-[40px] md:leading-[36px] lg:leading-[40px] heading-3"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </div>
          <div id="listCardContainer" className={styles.listContainer}>
            {features.map((card, index) => (
              <div
                key={`listItem-item--${index}`}
                data-card-name="listWrapper"
                data-card-index={`listWrapper-${index}`}
                className={`${styles.listItem} ${
                  index === 0 ? 'listItemActive' : ''
                }`}
              >
                <div className={`${styles.listItemImage}`}>
                  <div
                    className={`flex justify-center mx-24 w-full bg-display-200 pt-24`}
                  >
                    <Image
                      src={card?.image?.path}
                      width={312}
                      height={436}
                      alt={card?.image?.alternativeText}
                      className="object-cover object-center"
                    />
                  </div>
                </div>
                <div
                  onClick={e => listCardItemAction(e, card)}
                  className={styles.listItemCard}
                >
                  <p
                    className="mb-4 text-[16px] font-bold leading-[24px] paragraph-16"
                    dangerouslySetInnerHTML={{
                      __html: card?.title,
                    }}
                  />
                  <p
                    className="font-normal text-[16px] leading-[24px] paragraph-16"
                    dangerouslySetInnerHTML={{
                      __html: card?.description,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="hidden lg:flex justify-start px-0 mx-auto mt-24 w-full">
            {buttons?.map((props, index) => (
              <Button
                key={`${props.label}-${index}`}
                {...props}
                sectionReference={title}
                className="w-full md:w-max"
              />
            ))}
          </div>
        </div>

        <div
          id="listImageContainer"
          className="hidden md:flex justify-center p-16 w-full max-w-[510px] h-[582px] bg-display-200 rounded-[24px]"
        >
          <Image
            src={
              !!currentListCard?.image?.path
                ? currentListCard?.image?.path
                : features[0]?.image?.path
            }
            width={345}
            height={516}
            alt={features[0]?.image?.alternativeText}
            className="object-contain w-full h-full"
          />
        </div>
      </div>
      <div className="flex lg:hidden justify-center px-24 mx-auto mt-24 w-full">
        {buttons?.map((props, index) => (
          <Button
            key={`${props.label}-${index}`}
            {...props}
            sectionReference={title}
            className="w-full md:w-max"
          />
        ))}
      </div>
    </div>
  );
}
