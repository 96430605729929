'use client';

import { m, Variants } from 'framer-motion';
import classNames from 'classnames';
import Icon from '@/components/v3/Icon';
import { When } from '@/utils/when';

const TabDescriptionVariants: Variants = {
  open: {
    height: 'auto',
  },
  close: {
    height: 0,
    overflow: 'hidden',
  },
};

interface Props {
  title: string;
  items: { label: string; value: string }[];
  isOpen: boolean;
  setIsOpen: () => void;
}

export function TabDescription({ title, items, isOpen, setIsOpen }: Props) {
  return (
    <li>
      <button
        type="button"
        onClick={setIsOpen}
        className="flex justify-between py-16 w-full bg-transparent border-0 border-b border-display-300 border-solid"
      >
        <p
          className="font-semibold paragraph-18"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Icon
          name="chevron-down"
          className={classNames('fill-current transition-all transform-gpu', {
            'rotate-180': isOpen,
          })}
        />
      </button>
      <m.div
        variants={TabDescriptionVariants}
        animate={isOpen ? 'open' : 'close'}
        initial="close"
      >
        {items?.map(item => (
          <div
            key={item.label.toString()}
            className="flex flex-col md:flex-row md:gap-28 justify-between md:items-center py-16 border-b border-display-300"
          >
            <div>
              <p
                className="paragraph-18"
                dangerouslySetInnerHTML={{
                  __html: item.label,
                }}
              />

              <When expr={item?.label === 'Boletos não pagos'}>
                <p className="pt-8 text-display-600 paragraph-14">
                  Poderá haver cobrança caso o número de boletos efetivamente
                  pagos seja inferior a 40% do volume total de boletos no mês,
                  confira as condições nos termos e uso da conta
                </p>
              </When>
            </div>
            <p
              className="mt-4 md:mt-0 font-bold text-stone-500 paragraph-18"
              dangerouslySetInnerHTML={{
                __html: item.value,
              }}
            />
          </div>
        ))}
      </m.div>
    </li>
  );
}
