/* eslint-disable tailwindcss/no-custom-classname */

'use client';

import React from 'react';

import { ConditionsOrFees } from '@/types/components/api_response.interface';
import MemorizedDataForm from '@/components/MemorizedDataForm';

type TSmallCardPlan = {
  offerId: string;
  planName: string;
  description: string;
  fees?: ConditionsOrFees[];
  coupon?: string;
  onSelectPlan?: () => void;
  onSeeMoreDetails?: () => void;
};

const PlansCards: React.FC<TSmallCardPlan> = ({
  offerId,
  planName,
  description,
  coupon,
  onSelectPlan,
  onSeeMoreDetails,
}) => {
  return (
    <div className="flex flex-col justify-between p-24 w-[312px] sm:w-[290px] h-[296px] sm:h-[320px] md:h-[344px] bg-display-0 rounded-t-md rounded-b-md border border-display-300 border-solid">
      <div
        className={`mb-16 rounded-full border-2  border-stone-100 bg-stone-50`}
      >
        <p className={`text-sm font-semibold text-center  text-stone-700`}>
          🔥 Oferta válida apenas no site
        </p>
      </div>
      <h4 className="font-display heading-4">{planName}</h4>
      <div className="flex gap-4">
        <div className="flex flex-col w-full">
          <p className=" h-[4.5rem] paragraph-16">{description}</p>
        </div>
      </div>
      <div className="flex-col sm:mt-[16px]">
        <MemorizedDataForm
          offerId={offerId}
          coupon={coupon}
          onClick={onSelectPlan}
        />

        <div className="flex justify-center mt-16">
          <button
            type="button"
            className="font-semibold text-stone-700 hover:text-stone-600 underline"
            onClick={onSeeMoreDetails}
            data-testid="details-button-cards"
          >
            Mais detalhes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlansCards;
