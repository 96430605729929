'use client';

import { useResponsiveQuery } from 'atomic-layout';

import { TLinkItem } from '../../resources/links';
import { ExternalLink, InternalLink, NoLink } from './links';

type TLinks = {
  linkItems: TLinkItem[];
};

const Links: React.FC<TLinks> = ({ linkItems }) => {
  const isMobile = useResponsiveQuery({ to: 'md' });

  return (
    <>
      {isMobile ? (
        <div className="grid grid-cols-2 mb-[16px]">
          {linkItems.map(({ links, category }) => (
            <div
              className="flex flex-col pt-40 pr-16 text-display-500"
              key={category}
            >
              <p className="pb-12 text-display-500 paragraph-12">{category}</p>
              {links.map(({ name, isInternal, link, className, soon }) =>
                link ? (
                  isInternal ? (
                    <InternalLink
                      link={link}
                      name={name}
                      soon={soon}
                      key={name}
                    />
                  ) : (
                    <ExternalLink link={link} name={name} key={name} />
                  )
                ) : (
                  <NoLink name={name} className={className} key={name} />
                ),
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="grid lg:flex lg:flex-row md:grid-cols-3 gap-40 justify-between max-w-7xl">
          {linkItems.slice(0, 2).map(({ links, category }) => (
            <div
              className="flex flex-col lg:w-[180px] text-display-500"
              key={category}
            >
              <p className="pb-12 text-display-500 paragraph-12">{category}</p>
              {links.map(({ name, isInternal, link, className, soon }) =>
                link ? (
                  isInternal ? (
                    <InternalLink
                      link={link}
                      name={name}
                      soon={soon}
                      key={name}
                    />
                  ) : (
                    <ExternalLink link={link} name={name} key={name} />
                  )
                ) : (
                  <NoLink name={name} className={className} key={name} />
                ),
              )}
            </div>
          ))}

          <div className="flex flex-col gap-24">
            {linkItems.slice(2, 4).map(({ links, category }) => (
              <div className="flex flex-col lg:w-[180px]" key={category}>
                <p className="pb-12 text-display-500 paragraph-12">
                  {category}
                </p>
                {links.map(({ name, isInternal, link, className, soon }) =>
                  link ? (
                    isInternal ? (
                      <InternalLink
                        link={link}
                        name={name}
                        soon={soon}
                        key={name}
                      />
                    ) : (
                      <ExternalLink link={link} name={name} key={name} />
                    )
                  ) : (
                    <NoLink name={name} className={className} key={name} />
                  ),
                )}
              </div>
            ))}
          </div>

          {linkItems.slice(4).map(({ links, category }) => (
            <div
              className="flex flex-col pt-24 lg:pt-0 lg:w-[180px] text-display-500"
              key={category}
            >
              <p className="pb-12 text-display-500 paragraph-12">{category}</p>
              {links.map(({ name, isInternal, link, className, soon }) =>
                link ? (
                  isInternal ? (
                    <InternalLink
                      link={link}
                      name={name}
                      soon={soon}
                      key={name}
                    />
                  ) : (
                    <ExternalLink link={link} name={name} key={name} />
                  )
                ) : (
                  <NoLink name={name} className={className} key={name} />
                ),
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Links;
