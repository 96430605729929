import { InboundType } from '@/containers/InboundStone/interface';

type PreleadValues = Omit<InboundType, 'document'> & {
  offer_id: string;
  session_id: string;
  plan_id: string;
  client_address?: {
    city: string;
    state: string;
  };
  franchise_address?: {
    city: string;
    state: string;
  };
};

export async function sendPreleadValues(
  httpClient: (endpoint: string, options?: RequestInit) => Promise<Response>,
  data: PreleadValues,
) {
  const response = await httpClient('/preleads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Failed to send prelead values: ${response.statusText}`);
  }

  return response;
}
