'use client';

import Image from 'next/image';

import Icon from '@/components/v3/Icon';
import { FeatureCardIconProps, ImageResponsiveCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';

interface IconDescriptionCardProps {
  referId: string;
  title: string;
  description: string;
  iconsDescriptions: FeatureCardIconProps[];
  buttons?: ButtonProps[];
  images: ImageResponsiveCMSProps;
}
export function IconDescriptionCard({
  referId,
  title,
  description,
  images,
  iconsDescriptions,
  buttons,
}: IconDescriptionCardProps) {
  return (
    <section
      id={referId}
      className="flex justify-center px-24 pt-40 lg:pt-[40px] pb-40 lg:pb-[80px] w-full bg-display-0"
    >
      <div className="flex flex-col lg:flex-row gap-24 justify-center lg:justify-between w-full max-w-screen-xl">
        <div className="flex relative order-2 md:order-none lg:order-2 justify-center items-center w-full lg:max-w-[715px]">
          <div className="flex md:hidden overflow-hidden">
            <Image
              src={images.mobile.path}
              alt={images.mobile.alternativeText}
              width={239}
              height={204}
              quality={80}
              className="rounded-2xl"
            />
          </div>
          <div className="hidden md:flex lg:hidden overflow-hidden">
            <Image
              src={images.tablet.path}
              alt={images.tablet.alternativeText}
              width={494}
              height={409}
              quality={80}
              className="rounded-2xl"
            />
          </div>
          <div className="hidden lg:flex relative max-w-[549px] max-h-[469px]">
            <Image
              src={images.web.path}
              alt={images.web.alternativeText}
              width={549}
              height={469}
              quality={80}
              className="object-cover w-full h-full rounded-2xl"
            />
          </div>
        </div>

        <div className="flex flex-col w-full lg:min-w-[541px] lg:max-w-[541px]">
          <h2
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className="w-full md:w-[550px] lg:w-full font-display text-4xl font-bold leading-[38px] lg:leading-8"
          />
          <p className="mt-8 lg:mt-16 w-full font-normal text-lg lg:text-xl leading-[26px] lg:leading-5 text-left">
            {description}
          </p>
          <div className="flex flex-col gap-24 lg:my-24 mt-24 w-full">
            <div className="flex flex-col gap-16">
              {iconsDescriptions.map(
                ({ iconName, description, title, tag }, index) => (
                  <div className="flex gap-16 w-full" key={index}>
                    <Icon
                      name={iconName}
                      className="w-[24px] h-[24px] text-stone-500 fill-current"
                    />
                    <div className="flex flex-col gap-4">
                      <h2 className="font-display text-xl lg:text-2xl font-medium leading-5 lg:leading-6">
                        {title}{' '}
                        {tag && (
                          <span className="py-4 px-8 text-[16px] font-semibold bg-stone-200 rounded-md">
                            {tag}
                          </span>
                        )}
                      </h2>

                      <p className="lg:text-lg text-left paragraph-16">
                        {description}
                      </p>
                    </div>
                  </div>
                ),
              )}
            </div>
            {buttons && (
              <div className="hidden md:flex">
                {buttons?.map((props, index) => (
                  <Button
                    key={`${props.label}-${index}`}
                    {...props}
                    sectionReference={title}
                    className="relative w-full md:w-max"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
