'use client';

import { useResponsiveQuery } from 'atomic-layout';
import Image from 'next/image';

import Icon, { IconsNames } from '@/components/v3/Icon';
import { ImageCMSProps } from '@/types/cms';
import Button, { ButtonProps } from '../../base/ui/Button';

export interface RtbFeaturesProps {
  referId: string;
  title: string;
  description: string;
  image: ImageCMSProps;
  features: { title: string; description: string; iconName: IconsNames }[];
  buttons: ButtonProps[];
  analyticsSectionReference?: string;
}

export function RTB({
  referId,
  title,
  description,
  image,
  features,
  buttons,
}: RtbFeaturesProps) {
  const isTabletWeb = useResponsiveQuery({ from: 'md' });

  return (
    <div id={referId} className="relative lg:h-[880px] md:min-h-[648px]">
      <div className="flex flex-col-reverse md:flex-row gap-24 lg:gap-[40px] md:justify-between items-center md:items-start py-[40px] px-24 2xl:px-0 lg:pt-[80px] lg:pb-[120px] m-auto md:max-w-[768px] lg:max-w-[1280px]">
        <div className="flex justify-center items-center w-[298px] md:w-[326px] lg:w-[620px]">
          <Image
            src={image.path}
            width={421}
            height={540}
            alt={image.alternativeText}
            quality={80}
            style={{ objectFit: 'cover' }}
            className="w-full max-w-[421px] h-auto"
          />
        </div>
        <div className="flex items-center md:w-[349px] lg:w-[620px] md:h-[330px] lg:h-[540px]">
          <div className="flex flex-col md:gap-16 lg:gap-24">
            <div>
              <h2
                className="mb-8 font-display heading-3 md:heading-4 lg:heading-2"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />

              <p
                className="font-sharon text-[18px] lg:text-[20px] leading-[26px] lg:leading-5"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>

            {isTabletWeb && (
              <div className="flex z-10 md:flex-col lg:flex-row gap-8 lg:gap-[16px] md:mb-24 w-[232px] lg:w-auto">
                {buttons?.map((props, index) => (
                  <Button
                    key={`${props.label}-${index}`}
                    {...props}
                    sectionReference={title}
                    className="w-full md:w-max"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex absolute top-[260px] sm:top-[220px] md:top-[180px] lg:top-[205px] flex-col justify-center items-center py-16 pl-24 w-full h-full">
        <div className="flex overflow-x-auto gap-24 xl:justify-center pr-24 w-full">
          {features.map(({ title, description, iconName }, index) => (
            <div key={index}>
              <div className="flex flex-col p-16 md:p-24 w-[257px] md:w-[286px] lg:w-[302px] bg-display-100 rounded-md border-2 border-display-100">
                <div className="flex justify-center items-center mb-16 lg:mb-24 w-48 min-h-[48px] bg-stone-100 rounded-[8px]">
                  <Icon
                    name={iconName}
                    className="w-24 h-24 text-stone-500 fill-current"
                  />
                </div>
                <h3
                  className="font-display text-lg lg:text-xl font-bold leading-[28px] lg:leading-7"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />

                <p
                  className="md:max-w-[300px] lg:max-w-[330px] font-sharon text-[16px] lg:text-lg leading-[26px]"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex md:hidden px-24 mt-[124px] sm:mt-[130px] mb-40">
        {buttons?.map((props, index) => (
          <Button
            key={`${props.label}-${index}`}
            {...props}
            sectionReference={title}
            className="relative w-full md:w-max"
          />
        ))}
      </div>
    </div>
  );
}
