'use client';

import { useResponsiveQuery } from 'atomic-layout';
import Image from 'next/image';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import Icon from '@/components/v3/Icon';
import { handleTimeUpdate } from '@/helpers/handleTimeUpdate';

export type Props = {
  title: string;
  video: {
    src: string;
    volume?: number;
    quality?: number;
  };
  thumbnail?: {
    src: string;
    alt: string;
    quality?: number;
  };
  isLCP?: boolean;
  thereAreOtherVideoPlaying?: boolean;
  shouldPlayInSequence?: boolean;
  onInteract?: () => void;
  onPlay?: () => void;
  onEnd?: () => void;
  trackPartialReproduction?: (percentage: number) => void;
  trackPlayVideo?: () => void;
};

export type TrackStatus = {
  video_played: boolean;
  video_view_partial_25: boolean;
  video_view_partial_50: boolean;
  video_view_partial_100: boolean;
};

const Story: React.FC<Props> = ({
  video,
  thumbnail,
  isLCP,
  title,
  thereAreOtherVideoPlaying,
  shouldPlayInSequence,
  onInteract,
  onPlay,
  onEnd,
  trackPartialReproduction,
  trackPlayVideo,
}) => {
  const storyRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isShowingVideo, setIsShowingVideo] = useState(false);
  const [shouldShowThumbnail, setShouldShowThumbnail] = useState(true);
  const [trackStatus, setTrackStatus] = useState<TrackStatus>({
    video_played: false,
    video_view_partial_25: false,
    video_view_partial_50: false,
    video_view_partial_100: false,
  });
  const isMobile = useResponsiveQuery({ to: 'sm' });
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const pauseVideo = useCallback(() => {
    if (videoRef.current && !videoRef.current?.paused) {
      videoRef.current.pause();
      setIsShowingVideo(false);
    }
  }, []);

  const playVideo = useCallback(async () => {
    if (!videoRef.current?.paused) return;

    if (!inView) return;

    if (onPlay) onPlay();

    videoRef.current.volume = video?.volume || 1;

    try {
      setIsShowingVideo(true);
      setShouldShowThumbnail(false);
      await videoRef.current.play();
    } catch (error) {
      setIsShowingVideo(false);
      setShouldShowThumbnail(true);
    }
  }, [onPlay, video?.volume, inView]);

  function togglePlay() {
    return isShowingVideo ? pauseVideo() : playVideo();
  }

  useEffect(() => {
    async function handleExternalPlayVideoControl() {
      if (!shouldPlayInSequence) return;
      try {
        await playVideo();
      } catch (error) {}
    }

    handleExternalPlayVideoControl();
  }, [playVideo, shouldPlayInSequence]);

  useEffect(() => {
    if (thereAreOtherVideoPlaying && isShowingVideo) {
      pauseVideo();
    }
  }, [thereAreOtherVideoPlaying, pauseVideo, isShowingVideo]);

  useEffect(() => {
    if (!isShowingVideo || !storyRef.current || !isMobile) return;

    document
      .querySelector('#stories-scroll-container')
      ?.scrollTo(storyRef.current.offsetLeft - 34, 0);
  }, [isMobile, isShowingVideo]);

  useEffect(() => {
    if (!inView) {
      pauseVideo();
    }
  }, [inView, pauseVideo]);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const listener = () => {
        handleTimeUpdate(
          video,
          trackStatus,
          setTrackStatus,
          trackPartialReproduction,
          trackPlayVideo,
        );
      };
      video.addEventListener('timeupdate', listener);

      return () => {
        video.removeEventListener('timeupdate', listener);
      };
    }
  }, [trackStatus, setTrackStatus, , trackPartialReproduction, trackPlayVideo]);

  const videoURL = `https://storage.googleapis.com/public-assets-stg/site/videos/produtos-stone/${video.src}`;

  function onClick() {
    if (onInteract) onInteract();

    togglePlay();
  }

  return (
    <div ref={ref}>
      <article
        ref={storyRef}
        className="overflow-hidden relative w-[290px] min-w-[290px] h-[420px] rounded-xl cursor-pointer"
        onClick={onClick}
      >
        {thumbnail && (
          <Image
            src={thumbnail.src}
            alt={thumbnail.alt}
            style={{ objectFit: 'cover' }}
            layout="fill"
            priority={isLCP || false}
            quality={thumbnail?.quality || 70}
          />
        )}

        <video
          ref={videoRef}
          id="video1"
          className="relative h-[420px] opacity-0 transition-opacity"
          style={{ opacity: !shouldShowThumbnail ? 1 : 0 }}
          muted={false}
          onEnded={() => {
            setIsShowingVideo(false);
            setShouldShowThumbnail(true);
            onEnd && onEnd();
          }}
          playsInline
          preload={isLCP ? 'auto' : 'metadata'}
        >
          <source src={videoURL} type="video/webm" />
          <source src={videoURL} type="video/mp4" />
        </video>

        <div className="flex absolute bottom-0 z-10 justify-between items-end py-16 px-[20px] w-full">
          <h3 className="font-display text-xl font-bold text-display-0">
            {title}
          </h3>
          <div
            className={`flex justify-center items-center p-8 ${
              isShowingVideo ? '' : 'pl-[9px]'
            } w-[36px] h-[36px] bg-gray-800 bg-opacity-60 rounded-full`}
          >
            <Icon
              name={isShowingVideo ? 'pause' : 'play'}
              className="text-display-0 fill-current"
            />
          </div>
        </div>
      </article>
    </div>
  );
};

export default Story;
