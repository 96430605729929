'use client';

import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import * as yup from 'yup';

import Modal from '@/components/v3/Modal';
import FormikSelectInput from '@/containers/InboundStone/components/InboundFormV2/components/FormikSelectInput';
import FormikTextField from '@/containers/InboundStone/components/InboundFormV2/components/FormikTextField';
import { AmplitudeContext } from '@/contexts/amplitude';
import { uuid } from '@/helpers/uuid';
import { statesOptions } from '@/resources/inputs/state';
import { sendPreleadValues } from '@/services/sendPreleadValues';
import useStore from '@/store';
import { getGASessionId } from '@/utils/amplitude';
import { bffHttp, useHttpClient } from '@/utils/http-client';
import { phoneMask } from '@/utils/masks';

import Button from '../../base/ui/Button';

interface FranchiseFormProps {
  referId: string;
  title: string;
  description: string;
}

export function FranchiseForm({
  referId,
  title,
  description,
}: FranchiseFormProps) {
  const store = useStore();
  const [isModal, setIsModal] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [businessCitiesOptions, setBusinessCitiesOptions] = useState([]);
  const { analytics } = useContext(AmplitudeContext);
  const httpClient = useHttpClient(bffHttp);

  async function handleSubmit(
    values: Record<string, string | undefined>,
    { resetForm }: FormikHelpers<Record<string, string | undefined>>,
  ) {
    try {
      setIsModal(true);

      const formattedPhone = String(values.phone?.replace(/\D/g, ''));

      await sendPreleadValues(httpClient.fetch, {
        offer_id: '22222222-2222-2222-2222-222222222222',
        plan_id: '22222222-2222-2222-2222-222222222222',
        session_id: uuid(),
        tpv: undefined,
        full_name: String(values.name),
        email: String(values.email),
        phone: formattedPhone,
        client_address: {
          city: String(values.city),
          state: String(values.state),
        },
        franchise_address: {
          city: String(values.businessCity),
          state: String(values.businessState),
        },
        tracking: {
          coupon: store.coupon,
          utm_medium: store.utm_medium as string,
          utm_source: store.utm_source as string,
          utm_campaign: store.utm_campaign as string,
          utm_content: store.utm_content as string,
          utm_term: store.utm_term as string,
          utm_placement: store.utm_placement as string,
          fbid: store.fbid as string,
          gcid: getGASessionId(),
          client_id: store.client_id as string,
          user_id: store.user_id as string,
        },
      });

      resetForm();
    } catch (error) {
      console.error(error);
    }
  }

  async function handleCitiesOptions(
    state: string,
    setOptions: Dispatch<SetStateAction<never[]>>,
  ) {
    const cities = await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
    ).then(response => response.json());

    if (cities.length > 0) {
      setOptions(
        cities.map((city: { id: number; nome: string }) => ({
          value: city.nome,
          label: city.nome,
        })),
      );
    } else {
      setOptions([]);
    }
  }

  const trackOnBlurFields = (inputType: string) => {
    analytics?.track({
      event_type: `step ${inputType} completed`,
      event_properties: {
        name: `step ${inputType} completed`,
        description: `Evento disparado quando o usuário preenche o ${inputType}. Como propriedade será retornada qual o ${inputType} digitado`,
      },
    });
  };

  const schema = yup.object({
    name: yup.string().trim().required('Este campo é obrigatório!'),
    email: yup
      .string()
      .email('Informe um e-mail válido')
      .required('Este campo é obrigatório!'),
    phone: yup
      .string()
      .matches(/\(\d{2,}\) \d{4,}-\d{4}/g, 'Informe um telefone válido')
      .required('Este campo é obrigatório!'),
    state: yup.string().required('Este campo é obrigatório!'),
    city: yup.string().required('Este campo é obrigatório!'),
    businessState: yup.string().required('Este campo é obrigatório!'),
    businessCity: yup.string().required('Este campo é obrigatório!'),
  });

  return (
    <div
      id={referId}
      className="flex justify-center items-center py-40 lg:py-80 px-24 w-full bg-display-50"
    >
      <div className="flex flex-col md:flex-row gap-24 md:gap-40 justify-center items-center w-full">
        <div className="flex flex-col w-full max-w-[455px] md:max-w-[510px]">
          <h3
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className="w-full font-display heading-3"
          />
          {description && (
            <p
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              className="mt-8 w-full paragraph-18"
            />
          )}
        </div>
        <Formik<Record<string, string | undefined>>
          enableReinitialize
          initialValues={{
            name: '',
            email: '',
            phone: '',
            state: '',
            city: '',
            businessState: '',
            businessCity: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {props => {
            return (
              <Form className="flex flex-col gap-16 lg:gap-24 p-24 lg:p-40 w-full max-w-[455px] md:max-w-[510px] bg-display-0 rounded-2xl">
                <h5 className="font-display font-bold">
                  Preencha os campos abaixo
                </h5>
                <div className="flex flex-col gap-8 lg:gap-16 w-full">
                  <p>Informe seus dados pessoais</p>
                  <FormikTextField
                    label="Nome"
                    name="name"
                    onBlurCallBack={(
                      event: React.FocusEvent<HTMLInputElement, Element>,
                    ) => {
                      trackOnBlurFields(event.target.name);
                    }}
                  />
                  <FormikTextField
                    type="email"
                    name="email"
                    label="E-mail"
                    onBlurCallBack={(
                      event: React.FocusEvent<HTMLInputElement, Element>,
                    ) => {
                      trackOnBlurFields(event.target.name);
                    }}
                  />
                  <FormikTextField
                    type="tel"
                    name="phone"
                    label="Telefone"
                    maskFunction={phoneMask}
                    onBlurCallBack={(
                      event: React.FocusEvent<HTMLInputElement, Element>,
                    ) => {
                      trackOnBlurFields(event.target.name);
                    }}
                  />
                  <div className="flex flex-col md:flex-row gap-8 lg:gap-16 w-full">
                    <div className="flex-1">
                      <Field id="state" name="state" aria-label="Estado">
                        {(fieldProps: FieldProps) => (
                          <FormikSelectInput
                            label="Estado"
                            options={statesOptions}
                            iid="stateSelect"
                            id="react-select-state-input"
                            aria-label="Estado"
                            name={fieldProps.field.name}
                            {...fieldProps}
                            onBlurCallBack={(option: OptionTypeBase) => {
                              const name = 'revenue';
                              const value = option.value;
                              handleCitiesOptions(value, setCitiesOptions);
                              props.setFieldValue('city', undefined);

                              const inputs = document.querySelectorAll(
                                '.react-select-form__single-value',
                              );
                              const city = document.getElementById(
                                'react-select-citySelect-input',
                              );

                              if (inputs) {
                                inputs.forEach(
                                  label =>
                                    label.parentNode === city?.parentNode &&
                                    (label.textContent = ''),
                                );
                              }

                              if (!window) return;
                              analytics?.track({
                                event_type: `step ${name} completed ton`,
                                event_properties: {
                                  [`input_${name}`]: value,
                                  name: `step ${name} completed ton`,
                                  description:
                                    'Evento disparado quando o usuário seleciona o faturamento. Como propriedade será retornada qual faturamento selecionado',
                                },
                              });
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="flex-1">
                      <Field id="city" name="city" aria-label="Cidade">
                        {(fieldProps: FieldProps) => (
                          <FormikSelectInput
                            label="Cidade"
                            options={citiesOptions}
                            iid="citySelect"
                            id="react-select-city-input"
                            aria-label="Cidade"
                            name={fieldProps.field.name}
                            isDisabled={citiesOptions.length === 0}
                            {...fieldProps}
                            onBlurCallBack={(option: OptionTypeBase) => {
                              const name = 'revenue';
                              const value = option.value;
                              if (!window) return;
                              analytics?.track({
                                event_type: `step ${name} completed ton`,
                                event_properties: {
                                  [`input_${name}`]: value,
                                  name: `step ${name} completed ton`,
                                  description:
                                    'Evento disparado quando o usuário seleciona o faturamento. Como propriedade será retornada qual faturamento selecionado',
                                },
                              });
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-8 lg:gap-16">
                  <p>Informe onde você pretende implementar a franquia</p>
                  <div className="flex flex-col md:flex-row gap-8 lg:gap-16 w-full">
                    <div className="flex-1">
                      <Field
                        id="business-state"
                        name="businessState"
                        aria-label="Estado da franquia"
                      >
                        {(fieldProps: FieldProps) => (
                          <FormikSelectInput
                            label="Estado da franquia"
                            options={statesOptions}
                            iid="businessStateSelect"
                            id="react-select-business-state-input"
                            aria-label="Estado da franquia"
                            name={fieldProps.field.name}
                            {...fieldProps}
                            onBlurCallBack={(option: OptionTypeBase) => {
                              const name = 'revenue';
                              const value = option.value;
                              handleCitiesOptions(
                                value,
                                setBusinessCitiesOptions,
                              );
                              props.setFieldValue('businessCity', undefined);

                              const inputs = document.querySelectorAll(
                                '.react-select-form__single-value',
                              );
                              const city = document.getElementById(
                                'react-select-businessCitySelect-input',
                              );

                              if (inputs) {
                                inputs.forEach(
                                  label =>
                                    label.parentNode === city?.parentNode &&
                                    (label.textContent = ''),
                                );
                              }

                              if (!window) return;
                              analytics?.track({
                                event_type: `step ${name} completed ton`,
                                event_properties: {
                                  [`input_${name}`]: value,
                                  name: `step ${name} completed ton`,
                                  description:
                                    'Evento disparado quando o usuário seleciona o faturamento. Como propriedade será retornada qual faturamento selecionado',
                                },
                              });
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="flex-1">
                      <Field
                        id="business-city"
                        name="businessCity"
                        aria-label="Cidade da franquia"
                      >
                        {(fieldProps: FieldProps) => (
                          <FormikSelectInput
                            label="Cidade da franquia"
                            options={businessCitiesOptions}
                            iid="businessCitySelect"
                            id="react-select-business-city-input"
                            aria-label="Cidade da franquia"
                            name={fieldProps.field.name}
                            className="flex-1 w-full"
                            {...fieldProps}
                            disabled={true}
                            isDisabled={businessCitiesOptions.length <= 0}
                            onBlurCallBack={(option: OptionTypeBase) => {
                              const name = 'revenue';
                              const value = option.value;
                              if (!window) return;
                              analytics?.track({
                                event_type: `step ${name} completed ton`,
                                event_properties: {
                                  [`input_${name}`]: value,
                                  name: `step ${name} completed ton`,
                                  description:
                                    'Evento disparado quando o usuário seleciona o faturamento. Como propriedade será retornada qual faturamento selecionado',
                                },
                              });
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <Button
                  sectionReference={title}
                  data-testid="button-formik"
                  type="submit"
                  className="w-full"
                  onClick={() => {
                    analytics?.track({
                      event_type: 'Forms Button stone',
                      event_properties: {
                        name: 'Forms Button stone',
                        description: `Quando o usuário preencher os dados do formulário da ultima sessão e clicar em "quero conhecer"`,
                        section_reference:
                          'Planos com sistemas integrados pra você alavancar o seu negócio?',
                        cta_reference: 'quero conhecer',
                      },
                    });
                  }}
                  disabled={!props.isValid}
                >
                  Enviar
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
      {isModal && (
        <Modal
          open={isModal}
          closeOnClickOut
          onCloseClick={() => setIsModal(false)}
          className="flex justify-center items-center p-24 md:!p-40"
        >
          <div className="flex relative flex-col justify-center items-center w-full max-w-[324px]">
            <button
              type="button"
              onClick={() => setIsModal(false)}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className='after:inline-block absolute top-0 right-0 after:scale-[1.5] cursor-pointer after:content-["\00d7"]'
            />

            <div className="flex mb-32">
              <svg
                width="108"
                height="112"
                viewBox="0 0 108 112"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3973_4162)">
                  <path
                    d="M75.5551 95.3846C74.2444 98.4684 72.4109 100.846 69.9123 102.141C68.8103 101.406 67.5717 100.784 66.2161 100.253C63.6843 99.2603 60.7488 98.5776 57.5266 98.0393C57.0702 97.9651 56.6099 97.891 56.1417 97.8208C55.7048 97.7545 55.2621 97.6901 54.8154 97.6277C51.3045 97.1381 47.5205 96.7597 43.6039 96.2994C34.4989 95.2325 25.2457 94.5108 16.3339 90.5239C6.72761 86.227 5.9123 77.0108 5.92595 74.758C5.93766 72.5052 6.65934 70.0866 8.44406 68.7134C2.2356 60.4628 7.91937 54.8707 10.5291 53.4448C6.8973 46.3957 10.2073 38.4259 21.255 36.6198C22.6749 36.3877 24.5318 36.3116 26.6754 36.3311C28.8346 36.3526 31.2884 36.4715 33.8845 36.6276C34.7018 36.6783 37.6665 36.8285 37.7972 36.8343C37.5788 36.3604 37.6841 31.4392 37.7212 30.8677C37.8752 28.492 38.1873 26.1182 38.8271 23.8206C39.9194 19.9078 42.061 16.1629 45.4042 13.8574C47.8579 12.1663 50.8598 11.6982 53.7465 12.5837C56.8127 13.5238 57.8543 15.8449 57.7255 18.4742C57.6261 20.5086 56.9414 22.4591 56.3543 24.4096C55.5332 27.1286 55.1294 29.9588 55.2855 32.7968C55.7009 40.3433 59.9433 46.0524 71.9447 49.302C72.3056 49.3995 75.676 49.9456 75.7053 50.0939C77.9659 61.3892 80.5874 83.5684 75.5551 95.3846Z"
                    fill="#FFCBD9"
                  />
                  <path
                    d="M19.3236 58.867C19.3099 58.867 19.2982 58.867 19.2846 58.865C15.1827 58.3092 12.1126 56.4542 10.1621 53.3529C10.0762 53.2164 10.1172 53.035 10.2537 52.9492C10.3903 52.8633 10.5717 52.9043 10.6575 53.0408C12.5105 55.988 15.4401 57.7533 19.3626 58.2838C19.5225 58.3053 19.6357 58.4535 19.6142 58.6134C19.5927 58.7597 19.466 58.867 19.3236 58.867Z"
                    fill="#1D232A"
                  />
                  <path
                    d="M16.3354 72.8473C16.3315 72.8473 16.3276 72.8473 16.3237 72.8473C12.9805 72.7186 10.1777 71.3532 8.2174 68.8956C8.11597 68.7688 8.13743 68.5854 8.26421 68.484C8.39099 68.3826 8.57434 68.404 8.67577 68.5308C10.5268 70.85 13.1795 72.1393 16.3491 72.2621C16.511 72.268 16.6358 72.4045 16.6299 72.5664C16.6202 72.7244 16.4914 72.8473 16.3354 72.8473Z"
                    fill="#1D232A"
                  />
                  <path
                    d="M38.4076 41.944C38.3803 42.0825 38.2594 42.18 38.1209 42.18C38.1033 42.18 38.0838 42.1781 38.0643 42.1742C34.3057 41.4408 29.5328 41.1404 29.486 41.1365C29.3241 41.1268 29.2012 40.9883 29.211 40.8264C29.2207 40.6665 29.3612 40.5455 29.5211 40.5533C29.5679 40.5553 33.8649 40.8244 37.5436 41.4818L33.8844 36.6289C34.7017 36.6796 37.6664 36.8298 37.7952 36.8376C38.0214 38.9442 38.2847 40.9298 38.4057 41.8192V41.8251C38.4154 41.8621 38.4154 41.9031 38.4076 41.944Z"
                    fill="#1D232A"
                  />
                  <path
                    d="M76.0562 74.1582L75.5549 95.3856C74.2442 98.4693 72.4107 100.847 69.9121 102.142C68.81 101.407 67.5715 100.785 66.2159 100.254C63.6841 99.2612 60.7486 98.5785 57.5264 98.0402C62.2446 93.5014 70.5714 84.6636 76.0562 74.1582Z"
                    fill="#1D232A"
                  />
                  <path
                    d="M73.1755 78.9647C68.0008 86.2694 60.154 94.0304 56.1418 97.8222C55.7049 97.7559 55.2621 97.6915 54.8154 97.6291C58.5604 94.1202 66.9203 85.9807 72.3193 78.3581C72.487 78.1221 72.8147 78.0655 73.0507 78.2333C73.2887 78.401 73.3433 78.7287 73.1755 78.9647Z"
                    fill="#1D232A"
                  />
                  <path
                    d="M74.9365 48.1743C72.9801 66.6495 70.2319 85.0857 65.3419 103.422C65.0396 104.56 65.8081 105.701 66.9726 105.855L97.0416 111.88C97.9895 111.919 98.7112 111.236 99.0291 110.341C104.543 94.7856 107.646 71.3502 108 52.1748C108.017 51.1762 107.303 50.316 106.314 50.1814L77.1386 46.4442C76.0502 46.294 75.0535 47.0781 74.9365 48.1743Z"
                    fill="#00A868"
                  />
                  <path
                    d="M13.3551 26.718L16.9011 16.9031L26.718 13.3629L16.9031 9.81885L13.3629 0L9.81885 9.81495L0 13.3551L9.81495 16.9011L13.3551 26.718Z"
                    fill="#42EC9A"
                  />
                  <path
                    d="M68.8331 35.5091L71.4137 28.3663L78.5564 25.7916L71.4156 23.2111L68.839 16.0684L66.2585 23.2092L59.1157 25.7858L66.2585 28.3663L68.8331 35.5091Z"
                    fill="#42EC9A"
                  />
                  <path
                    d="M81.3107 22.8001L82.7248 18.8855L86.6395 17.4753L82.7268 16.0611L81.3146 12.1465L79.9005 16.0592L75.9858 17.4714L79.9005 18.8855L81.3107 22.8001Z"
                    fill="#42EC9A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3973_4162">
                    <rect width="108" height="111.881" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="flex flex-col gap-8 items-center w-full">
              <h6 className="text-center heading-6">
                Agradecemos o seu interesse!
              </h6>
              <p className="text-center paragraph-18">
                Em breve nosso time entrará em
                <br />
                contato com você
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
