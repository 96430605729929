'use client';

import React, { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { YouTubeProps } from 'react-youtube';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const YouTube = dynamic<any | YouTubeProps>(() => import('react-youtube'), {
  ssr: false,
});

import styles from './Video.module.css';
import Image from 'next/image';
import Icon from '@/components/v3/Icon';
import { PrettyVideoProps } from '..';
import classNames from 'classnames';

export function Player({
  videoUrl,
  thumbnail,
  thumbnailQuality,
  description,
  author,
  caption,
}: PrettyVideoProps) {
  const [wasPlayedVideo, setWasPlayedVideo] = useState(false);
  const [isShowingDescription, setIsShowingDescription] = useState(true);

  const toggleShowVideo = useCallback(
    () => setWasPlayedVideo(value => !value),
    [],
  );

  const youtubeVideoId = videoUrl.includes('https://youtu.be/')
    ? videoUrl.replace('https://youtu.be/', '')
    : videoUrl;

  useEffect(() => {
    if (wasPlayedVideo) {
      setIsShowingDescription(false);
    }
  }, [wasPlayedVideo]);

  return (
    <>
      <div className="flex overflow-hidden relative justify-center items-center w-full h-full max-h-[242px] md:max-h-[max-content]">
        <div
          className={classNames(styles.video_container, {
            hidden: !wasPlayedVideo,
          })}
        >
          {wasPlayedVideo && (
            <YouTube
              videoId={youtubeVideoId}
              opts={{ playerVars: { autoplay: 1, rel: 0 } }}
              onReady={(e: { target: { playVideo: () => void } }) =>
                e.target.playVideo()
              }
              autoplay
            />
          )}
        </div>

        <div
          className={classNames(
            {
              hidden: wasPlayedVideo,
            },
            'flex relative justify-center items-center h-full w-full',
          )}
        >
          <div
            className={styles.play_button_container}
            onClick={toggleShowVideo}
          >
            <Icon name="play" className="w-9 lg:w-14 h-9 lg:h-14" />
          </div>
          <div
            className="h-full min-h-[242px] md:min-h-[537px] lg:min-h-[629px] cursor-pointer"
            onClick={toggleShowVideo}
          >
            <Image
              src={thumbnail.path}
              alt={thumbnail.alternativeText}
              quality={thumbnailQuality}
              fill
              className="object-cover h-full"
            />
          </div>
        </div>
      </div>

      <div
        className={classNames(
          'relative md:absolute md:bottom-0 w-full h-min select-none',
          {
            'md:opacity-0 md:pointer-events-none': !isShowingDescription,
          },
        )}
      >
        <div className="flex relative z-20 flex-col justify-end p-24 lg:p-40 w-full h-full bg-stone-900 md:bg-transparent">
          {!!description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="mb-16 font-display lg:text-2xl font-medium lg:leading-6 text-white paragraph-20"
            />
          )}

          {!!author && (
            <p
              dangerouslySetInnerHTML={{ __html: author }}
              className="text-stone-200 paragraph-16 lg:paragraph-18"
            />
          )}

          {!!caption && (
            <p
              dangerouslySetInnerHTML={{ __html: caption }}
              className="text-display-0 paragraph-16 lg:paragraph-18"
            />
          )}
        </div>

        <div className="relative z-10 w-full h-full">
          <div className={styles.Shadow} />
        </div>
      </div>
    </>
  );
}
