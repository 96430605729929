'use client';

import React, { useEffect, useRef, useState } from 'react';

import { Props as StoryProps } from './Story';
export { default as Story } from './Story';

type Props = {
  children: React.ReactElement<StoryProps>[];
  className?: string;
};

const StoryVideos: React.FC<Props> = ({ children, className }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [shouldStopAutoplayLogic, setShouldStopAutoplayLogic] = useState(false);
  const [isPlayingIndex, setIsPlayingIndex] = useState<number | undefined>();

  useEffect(() => {
    if (activeIndex + 1 === children.length) {
      setShouldStopAutoplayLogic(true);
    }
  }, [activeIndex, children]);

  return (
    <div
      className={`overflow-x-auto ${className}`}
      id="stories-scroll-container"
    >
      <div
        className="flex gap-24 sm:gap-32 justify-center m-auto w-max"
        ref={scrollContainerRef}
      >
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              shouldPlayInSequence: false,
              thereAreOtherVideoPlaying:
                typeof isPlayingIndex !== 'undefined' &&
                isPlayingIndex !== index,
              onInteract: () => {
                if (!shouldStopAutoplayLogic) {
                  setShouldStopAutoplayLogic(true);
                }

                setIsPlayingIndex(index);
              },
              onPlay: () => {
                setIsPlayingIndex(index);
              },
              onEnd: () => {
                setIsPlayingIndex(undefined);

                if (shouldStopAutoplayLogic) return;

                setActiveIndex(prevIndex => {
                  const newIndex = prevIndex + 1;

                  if (newIndex > children.length) return prevIndex;

                  setIsPlayingIndex(newIndex);

                  return newIndex;
                });
              },
            });
          }
          return child;
        })}
      </div>
    </div>
  );
};

export default StoryVideos;
