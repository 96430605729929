'use client';

import React from 'react';

import { ConditionsOrFees } from '@/types/components/api_response.interface';
import Button from '@/app/components/base/ui/Button';

type TSmallCardPlan = {
  offerId?: string;
  planName: string;
  description: string;
  fees?: ConditionsOrFees[];
  coupon?: string;
  onSelectPlan?: () => void;
  onSeeMoreDetails?: () => void;
};

const PlansCardFlex: React.FC<TSmallCardPlan> = ({
  planName,
  description,
  onSelectPlan,
  onSeeMoreDetails,
}) => {
  return (
    <div className="flex flex-col justify-between p-24 w-[312px] sm:w-[290px] h-[320px] sm:h-[320px] md:h-[344px] bg-display-0 rounded-t-md rounded-b-md border border-display-300 border-solid">
      <div className="mb-16 bg-blue-100 rounded-full border-2 border-stone-100">
        <p className={`text-sm font-semibold text-center text-blue-500`}>
          🤩 Maquininha pode ser grátis
        </p>
      </div>
      <h4 className="font-display heading-4">{planName}</h4>
      <div className="flex gap-4">
        <div className="flex flex-col w-full">
          <p className="h-[4.5rem] paragraph-16">{description}</p>
        </div>
      </div>
      <div className="flex-col sm:mt-[16px]">
        <Button
          className="!w-full min-h-[56px]"
          onClick={onSelectPlan}
          data-testid="flex-button"
          sectionReference={planName}
        >
          Fale com a gente
        </Button>

        <div className="flex justify-center mt-16">
          <button
            type="button"
            className="font-semibold text-stone-700 hover:text-stone-600 underline"
            onClick={onSeeMoreDetails}
            data-testid="details-button"
          >
            Mais detalhes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlansCardFlex;
