'use client';

import React from 'react';

import ButtonOpenYourAccount from '@/components/ButtonOpenYourAccount';
import LINKS from '@/constants/links';

type TProps = {
  onSelectPlan?: () => void;
  onSeeMoreDetails: () => void;
};

const PlansCardPj: React.FC<TProps> = ({ onSelectPlan, onSeeMoreDetails }) => {
  return (
    <div className="flex flex-col justify-between p-24 w-[312px] sm:w-[290px] h-[296px] sm:h-[320px] md:h-[344px] bg-display-0 rounded-t-md rounded-b-md border border-display-300 border-solid">
      <div className="mb-16 bg-display-100 rounded-full border-2 border-display-200">
        <p className="text-sm font-semibold text-center text-display-800">
          💼 Pensada e exclusiva pra PJ
        </p>
      </div>
      <h4 className="font-display heading-4">Modo Conta PJ</h4>
      <div className="flex gap-4">
        <div className="flex flex-col w-full">
          <p className="h-[4.5rem] paragraph-16">
            Conta PJ com cartão de débito, vendas por link, Pix, boleto e muito
            mais
          </p>
        </div>
      </div>
      <div className="flex-col mt-8 sm:mt-[16px]">
        <span onClick={onSelectPlan}>
          <ButtonOpenYourAccount
            qrCodeLink={LINKS.APP_LINK_APPSFLYER_QR_CODE_PRODUTOS_STONE}
            className="!w-full min-h-[56px] text-center btn-secondary"
            sectionReference="Modo Conta PJ"
          >
            Abra sua conta grátis
          </ButtonOpenYourAccount>
        </span>
        <div className="flex justify-center mt-16">
          <button
            type="button"
            className="font-semibold text-stone-700 hover:text-stone-600 underline"
            onClick={onSeeMoreDetails}
            data-testid="details-button-pj"
          >
            Mais detalhes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlansCardPj;
