'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { useState } from 'react';

import HeroModal from '@/components/HeroModal';
import { ImageResponsiveCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';

export type HeroProps = {
  referId?: string;
  tag: string;
  title: string;
  description: string;
  images: ImageResponsiveCMSProps;
  buttons: ButtonProps[];
  analyticsSectionReference?: string;
  imageBorder?:
    | 'top-left'
    | 'bottom-ritgh'
    | 'bottom-left'
    | 'top-right'
    | 'full';
  background?: string;
  isWhiteText?: boolean;
  className?: string;
  inCarousel?: boolean;
  modalLabel?: string;
  modalContent?: string;
  modalTitle?: string;
};

export function DefaultHero({
  referId,
  tag,
  title,
  description,
  images,
  buttons,
  imageBorder = 'bottom-ritgh',
  background,
  className,
  isWhiteText = false,
  inCarousel = false,
  modalLabel,
  modalContent,
  modalTitle,
}: HeroProps) {
  const isBorderFull = imageBorder === 'full';
  const isModal = modalLabel && modalContent && modalTitle;
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <section
      id={referId}
      className={classNames(
        'flex w-full bg-display-0 md:bg-display-50',
        className,
      )}
      style={{
        background: background,
      }}
    >
      <div
        className={classNames(
          'flex flex-col md:flex-row gap-16 md:gap-24 lg:gap-40 justify-between md:justify-center lg:justify-between items-center md:pl-24 xl:pl-16 2xl:pl-0 m-auto w-full max-w-[455px] md:max-w-[1280px]',
          {
            'pt-24 md:pt-0 gap-24': isBorderFull,
            'items-start md:items-center mt-0 !xs:pt-40 !md:pt-0 md:h-full':
              inCarousel,
          },
        )}
      >
        <div
          className={classNames(
            'md:hidden overflow-hidden relative w-full h-[300px]',
            {
              'flex h-[312px] w-[calc(100%-48px)] order-2 rounded-2xl mb-24':
                isBorderFull,
              'rounded-br-[42px]': !isBorderFull,
              'mb-0': inCarousel,
            },
          )}
        >
          <Image
            src={images.mobile.path}
            alt={images.mobile.alternativeText}
            layout="fill"
            quality={80}
            className={`object-cover ${
              isBorderFull ? 'rounded-2xl' : 'rounded-br-[42px]'
            }`}
          />
        </div>

        <div
          className={classNames(
            'flex flex-col md:flex-1 lg:flex-initial gap-8 lg:gap-16 items-center md:items-start px-24 md:px-0 pb-40 md:pb-0 lg:max-w-[565px] text-center md:text-left',
            {
              'pb-0': isBorderFull,
              '!text-left': inCarousel,
            },
          )}
        >
          {tag && (
            <p
              className="mb-8 font-bold text-stone-700 uppercase paragraph-14"
              dangerouslySetInnerHTML={{
                __html: tag,
              }}
            />
          )}

          <h1
            className={classNames(
              'font-display text-[36px] lg:text-[48px] font-black leading-[44px] lg:leading-[56px] text-center md:text-left',
              {
                'text-display-0 max-w-[290px] xs:max-w-none': isWhiteText,
                '!text-left max-w-none': inCarousel,
                'xl:w-[620px]': isModal,
              },
            )}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />

          <p
            className={classNames(
              'w-full text-lg lg:text-2xl leading-[26px] lg:leading-6 text-center md:text-left',
              {
                'text-display-0': isWhiteText,
                '!text-left': inCarousel,
                '!text-base leading-4 lg:!text-lg lg:leading-[26px]': isModal,
              },
            )}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />

          {isModal && (
            <>
              <span
                onClick={() => {
                  setShowModal(true);
                }}
                className="decoration-slice text-lg leading-[26px] text-stone-300 underline cursor-pointer"
              >
                {modalLabel}
              </span>
              <HeroModal
                showModal={showModal}
                closeModal={closeModal}
                title={modalTitle}
                content={modalContent}
              />
            </>
          )}

          {buttons?.length && (
            <div className="flex flex-col md:flex-row gap-16 mt-8 w-full">
              {buttons?.map((props, index) => (
                <Button
                  key={`${props.label}-${index}`}
                  {...props}
                  sectionReference={title}
                  className={classNames({
                    'w-max': inCarousel,
                    'w-full md:w-max': !inCarousel,
                  })}
                />
              ))}
            </div>
          )}
        </div>
        <div
          className={classNames(
            'hidden md:flex lg:hidden overflow-hidden flex-1 lg:flex-initial md:min-w-[372px] md:min-h-[576px] lg:rounded-br-10xl',
            {
              'md:min-h-[364px] md:items-center': isBorderFull,
            },
          )}
        >
          <Image
            src={images.tablet.path}
            alt={images.tablet.alternativeText}
            width={inCarousel ? 362 : isBorderFull ? 348 : 500}
            height={inCarousel ? 380 : isBorderFull ? 284 : 576}
            quality={80}
            className={classNames('object-cover rounded-br-6xl', {
              'rounded-2xl ': isBorderFull,
            })}
          />
        </div>

        <div
          className={classNames(
            'hidden lg:flex overflow-hidden flex-1 lg:flex-initial lg:min-w-[500px] lg:min-h-[576px]',
            {
              'lg:min-h-[660px] lg:items-center mr-24': isBorderFull,
              '!mr-0': inCarousel,
            },
          )}
        >
          <Image
            src={images.web.path}
            alt={images.web.alternativeText}
            width={inCarousel ? 556 : isBorderFull ? 600 : 675}
            height={inCarousel ? 464 : isBorderFull ? 500 : 576}
            className={classNames('object-cover overflow-hidden', {
              'rounded-tl-6xl lg:rounded-tl-10xl': imageBorder === 'top-left',
              'rounded-br-6xl lg:rounded-br-10xl':
                imageBorder === 'bottom-ritgh' || imageBorder === null,
              'rounded-bl-6xl lg:rounded-bl-10xl':
                imageBorder === 'bottom-left',
              'rounded-tr-6xl lg:rounded-tr-10xl': imageBorder === 'top-right',
              'lg:rounded-6xl': isBorderFull,
            })}
          />
        </div>
      </div>
    </section>
  );
}
