'use client';

import Image from 'next/image';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Icon from '@/components/v3/Icon';

const ServiceConsumerVideo: React.FC<{
  classImage?: string;
  classVideo?: string;
  width?: number;
  height?: number;
  img: string;
  videoUrl: string;
}> = ({ width, height, classImage, classVideo, img, videoUrl }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [shouldShowThumbnail, setShouldShowThumbnail] = useState(true);

  const playVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.play().then(() => {
        setIsPlaying(true);
        setShouldShowThumbnail(false);
      });
    }
  }, []);

  const pauseVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, []);

  const togglePlay = () => {
    isPlaying ? pauseVideo() : playVideo();
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        pauseVideo();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [pauseVideo]);

  return (
    <div className="flex justify-center items-center">
      <div className="relative rounded-[24px]">
        <div className={classVideo}>
          <video
            width={width}
            height={height}
            ref={videoRef}
            muted={false}
            controls
            style={{
              border: '4px',
              borderRadius: '16px',
              objectFit: 'cover',
            }}
          >
            <source src={videoUrl} type="video/webm" />
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
        <div
          className={`absolute inset-0 flex items-center justify-center cursor-pointer ${classImage}  ${
            shouldShowThumbnail ? '' : 'hidden'
          }`}
          onClick={togglePlay}
        >
          <Image
            width={width && width}
            height={height && height}
            src={img}
            alt={'Atendimento e presença'}
            className="object-cover w-full h-fit"
            style={{ borderRadius: '16px' }}
            layout={width || height ? '' : 'fill'}
          />
          {!isPlaying && (
            <div className="flex absolute inset-0 justify-center items-center">
              <div className="flex justify-center items-center p-8 md:p-[14px] lg:p-16 bg-black bg-opacity-60 rounded-full">
                <Icon
                  name="play"
                  className="md:w-[36px] lg:w-[48px] md:h-[36px] lg:h-[48px] text-display-0 fill-current"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceConsumerVideo;
