'use client';

import Modal from '@/components/v3/Modal';

type THeroModal = {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  content: string;
};

const HeroModal: React.FC<THeroModal> = ({
  showModal,
  closeModal,
  title,
  content,
}) => {
  return (
    <Modal
      open={showModal}
      onCloseClick={() => closeModal()}
      data-testid="hero-modal"
      className="gap-24 items-center sm:items-start !p-24 lg:!p-40 max-w-[512px] h-auto"
    >
      <div className="flex flex-col gap-24 max-w-[624px]">
        <div className="flex gap-40 justify-between items-start">
          <h5
            className="font-display font-bold"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />

          <button
            type="button"
            onClick={() => closeModal()}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className='after:inline-block after:scale-[1.5] cursor-pointer after:content-["\00d7"]'
          ></button>
        </div>
        <div className="flex flex-col gap-16">
          <div
            className="flex flex-col gap-8"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
export default HeroModal;
